import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleColDef } from "./use-roleAssignment-columnDefs";

export const useColDefLastName = (): RoleColDef => {
  const { t } = useTranslation();

  return useMemo(() => ({
    colId: "lastName",
    sort: "asc",
    field: "user.lastName",
    headerName: t("app:roleManagementView.field.lastName.label") as string,
  }), [t]);
};
