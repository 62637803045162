import { Form, Input, Modal, Space } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./LayoutProfileDialog.module.less";

export interface LayoutProfileDialogProps {
  dialogTitle: string;
  layoutProfileIndex: number;
  onSave: (description: string) => void;
  onCancel: () => void;
  currentDescription?: string;
}

export const LayoutProfileDialog: FC<LayoutProfileDialogProps> = ({ dialogTitle, layoutProfileIndex, onSave, onCancel, currentDescription }) => {
  const { t } = useTranslation();

  const [isFormValid, setIsFormValid] = useState(false);
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, formValues]);

  const onOk = useCallback(() => {
    const futureLayoutProfileDescription: string = form.getFieldValue(fieldNameLayoutProfileDescription);
    onSave(futureLayoutProfileDescription);
  }, [form, onSave]);

  const afterOpenChange = useCallback((open: boolean) => {
    if (open) {
      form.validateFields() // update UI with validation errors
        .catch(() => undefined) // ignore validation errors
        .finally(() => form.getFieldInstance(fieldNameLayoutProfileDescription)?.focus()); // focus first input field
    } else {
      form.resetFields();
    }
  }, [form]);

  return (
    <Modal
      open={true}
      centered={true}
      closeIcon={true}
      onOk={onOk}
      okText={t("common:save")}
      onCancel={onCancel}
      cancelText={t("common:cancel")}
      okButtonProps={{ disabled: !isFormValid }}
      afterOpenChange={afterOpenChange}
      destroyOnClose={true}
    >
      <Space direction="vertical" className={styles.message}>{dialogTitle}</Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          colon={false}
          initialValues={{ layoutProfileNumber: layoutProfileIndex + 1, layoutProfileDescription: currentDescription }}
          form={form}
        >
          <Form.Item
            name="layoutProfileNumber"
            label={t("app:operatorView.layoutProfiles.dialog.profileNumber")}
            required={true}
          >
            <Input
              autoComplete="off"
              readOnly={true}
              value={layoutProfileIndex}
            />
          </Form.Item>
          <Form.Item
            name={fieldNameLayoutProfileDescription}
            label={t("app:operatorView.layoutProfiles.dialog.profileDescription.label")}
            required={true}
            hasFeedback={true}
            validateTrigger="onChange"
            rules={[
              { required: true, message: t("app:operatorView.layoutProfiles.dialog.profileDescription.validation.required") as string },
            ]}
          >
            <Input autoComplete="off"/>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

const fieldNameLayoutProfileDescription = "layoutProfileDescription";
