import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditPartlistMarketingDataDialogData } from "../types/marketingDataDialog/editPartlistMarketingDataDialogTypes";
import { EditProjectStructureFolderDialogData } from "../types/marketingDataDialog/editProjectStructureFolderDialogTypes";

export const marketingDataDialogSliceName = "marketingDataDialog";

export enum MarketingDataDialogUseCase {
  PARTLIST = "PARTLIST",
  PROJECT_STRUCTURE = "PROJECT_STRUCTURE",
}

export interface MarketingDataDialogState<ActiveType extends MarketingDataDialogInstanceBase, RequestedType extends MarketingDataDialogInstanceBase> {
  active?: ActiveType;
  requested?: RequestedType;
}

export type MarketingDataDialogInstance = EditPartlistMarketingDataDialogInstance | EditProjectStructureFolderDialogInstance;

export type MarketingDataDialogInstanceBase = {
  useCase: MarketingDataDialogUseCase;
  hasChanges: boolean;
};

export type EditPartlistMarketingDataDialogInstance = MarketingDataDialogInstanceBase & {
  useCase: MarketingDataDialogUseCase.PARTLIST;
  data: EditPartlistMarketingDataDialogData;
};

export type EditProjectStructureFolderDialogInstance = MarketingDataDialogInstanceBase & {
  useCase: MarketingDataDialogUseCase.PROJECT_STRUCTURE;
  data: EditProjectStructureFolderDialogData;
};

export type MarketingDataDialogRootState = {
  [marketingDataDialogSliceName]: MarketingDataDialogState<MarketingDataDialogInstance, MarketingDataDialogInstance>;
};

const initialState: MarketingDataDialogState<MarketingDataDialogInstanceBase, MarketingDataDialogInstanceBase> = {};

const marketingDataDialogSlice = createSlice({
  name: marketingDataDialogSliceName,
  initialState,
  reducers: {
    requestMarketingDataDialog: (state, action: PayloadAction<Omit<MarketingDataDialogInstance, "hasChanges">>) => {
      const newDialogInstance = { ...action.payload, hasChanges: false };
      if (state.active?.hasChanges) {
        state.requested = newDialogInstance;
      } else {
        state.active = newDialogInstance;
      }
    },
    cancelMarketingDataDialogRequest: (state) => {
      state.requested = undefined;
    },
    closeMarketingDataDialog: (state) => {
      state.active = state.requested;
      state.requested = undefined;
    },
    setMarketingDataDialogHasChanges: (state) => {
      if (state.active) state.active.hasChanges = true;
      else console.error("Could not set hasChanges flag, no active dialog found.");
    },
  },
});

export const selectMarketingDataDialog = (state: MarketingDataDialogRootState) => state[marketingDataDialogSliceName];

export const {
  requestMarketingDataDialog,
  cancelMarketingDataDialogRequest,
  closeMarketingDataDialog,
  setMarketingDataDialogHasChanges,
} = marketingDataDialogSlice.actions;
export const marketingDataDialogReducer = marketingDataDialogSlice.reducer;
