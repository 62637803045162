export interface LayoutProfile {
  name: string;
  data: Record<string, string | undefined>;
}

export enum LayoutProfileDataKey {
  COLUMN_IDS_GEOMETRY_VIEW = "geometry.geometryView.columnIds",
  COLUMN_IDS_PARTLIST_VIEW = "project.partlistView.columnIds",
  COLUMN_IDS_MATERIAL_INFO_VIEW = "project.materialInfoView.columnIds",
  COLUMN_IDS_TARGET_MANAGER_VIEW = "project.targetManagerView.columnIds",
  COLUMN_IDS_TMM_VIEW = "project.tmmView.columnIds",
  LAYOUT_PROFILE_GEOMETRY_MANAGER_VIEW_ASSEMBLY_MANAGER = "geometry.geometryManagerView.assemblyManager.layoutProfile",
  LAYOUT_PROFILE_TMM_EDITOR_VIEW = "project.tmmEditorView.layoutProfile",
  LANGUAGE = "language",
  LAYOUT = "layout",
  THEME = "theme",
}
