import { MaterialInfoColumn } from "@org-avp/avp-avengers-materialinfo-service-api";
import { dndHandleColumnId } from "@org-avp/avp-avengers-ui-framework-components";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export const materialInfoViewOptionsSliceName = "materialInfo";
export const materialInfoColumnIds = [
  dndHandleColumnId,
  MaterialInfoColumn.SOURCE,
  MaterialInfoColumn.CONNECTED_TO_TMM,
  MaterialInfoColumn.PART_KEY,
  MaterialInfoColumn.ORIGINAL_RULE,
  MaterialInfoColumn.LABEL,
  MaterialInfoColumn.COL_FAMILY,
  MaterialInfoColumn.COLOR_RULE,
  MaterialInfoColumn.COLOR_KEY,
  MaterialInfoColumn.MATERIAL_CODE,
  MaterialInfoColumn.MATERIAL_TYPE,
  MaterialInfoColumn.INDEX,
  MaterialInfoColumn.ADI,
  MaterialInfoColumn.MODEL_YEAR,
  MaterialInfoColumn.INCLUSION_DATE,
  MaterialInfoColumn.EXCLUSION_DATE,
];
export type MaterialInfoViewOptionsState = {
  columns: string[];
};

const persistedMaterialInfoViewOptions = localStorage.getItem(materialInfoViewOptionsSliceName);
const persistedState = persistedMaterialInfoViewOptions ? JSON.parse(persistedMaterialInfoViewOptions) as MaterialInfoViewOptionsState : null;
const fallback: MaterialInfoViewOptionsState = {
  columns: materialInfoColumnIds,
};
const initialState: MaterialInfoViewOptionsState = persistedState ?? fallback;
// if columns does not exist, set default fallback value
if (initialState.columns === undefined || initialState.columns.length === 0) {
  initialState.columns = fallback.columns;
}

const materialInfoViewOptionsSlice = createSlice({
  name: materialInfoViewOptionsSliceName,
  initialState,
  reducers: {
    setMaterialInfoViewColumns(state: MaterialInfoViewOptionsState, action: PayloadAction<string[]>) {
      const next: MaterialInfoViewOptionsState = { ...state, columns: action.payload };
      persistState(next);
      return next;
    },
  },
});

export type MaterialInfoViewOptionsRootState = { [materialInfoViewOptionsSliceName]: MaterialInfoViewOptionsState };
export const selectMaterialInfoViewOptions = (state: MaterialInfoViewOptionsRootState) => state[materialInfoViewOptionsSliceName];
export const setMaterialInfoViewColumns = (columns: string[]) => (dispatch: Dispatch) => {
  dispatch(materialInfoViewOptionsSlice.actions.setMaterialInfoViewColumns(columns));
};

function persistState(state: MaterialInfoViewOptionsState) {
  localStorage.setItem(materialInfoViewOptionsSliceName, JSON.stringify(state));
}

export const materialInfoViewOptionsReducer = materialInfoViewOptionsSlice.reducer;
