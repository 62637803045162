import { Target } from "@org-avp/avp-avengers-tmm-service-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const tmmEditorSliceName = "tmmEditorSlice";

export interface TmmEditorState {
  target: Target | undefined;
}

export interface ChangeParam {
  target: Target | undefined;
}

export type TmmEditorRootState = { [tmmEditorSliceName]: TmmEditorState };

const initialState: TmmEditorState = { target: undefined };

const tmmEditorSlice = createSlice({
  name: tmmEditorSliceName,
  initialState,
  reducers: {
    changeTarget: (state, action: PayloadAction<ChangeParam>) => {
      state.target = action.payload.target;
    },
  },
});

export const selectTarget = (state: TmmEditorRootState) => state[tmmEditorSliceName].target;

export const { changeTarget } = tmmEditorSlice.actions;
export const tmmEditorReducer = tmmEditorSlice.reducer;
