import { UserOutlined } from "@ant-design/icons";
import { User } from "@org-avp/avp-avengers-user-service-api";
import { Form, Select } from "antd";
import { FC, useCallback, useEffect, useMemo } from "react";

import styles from "./UserSelect.module.less";

export interface UserSelectProps {
  label: string;
  placeHolder?: string;
  users?: User[];
  selectedUserId?: string;
  onUserSelect?: (user: User) => void;
}

export const UserSelect: FC<UserSelectProps> = ({ label, users, selectedUserId, onUserSelect, placeHolder }) => {
  const [form] = Form.useForm();

  const userLookup = useMemo(() => {
    return new Map((users ?? []).map((user) => [user.userId, user]));
  }, [users]);

  const userOptions = useMemo(() => {
    return (users ?? []).map((user) => createUserOption(user));
  }, [users]);

  useEffect(() => {
    if (selectedUserId) {
      const selectedUser = userLookup.get(selectedUserId);
      if (selectedUser && selectedUser.userId !== form.getFieldValue("userId")) {
        form.setFieldValue("userId", selectedUserId);
      }
    }
  }, [form, selectedUserId, userLookup]);

  const onSelectUserOption = useCallback((userId: string) => {
    const user = userLookup.get(userId);
    if (onUserSelect && user) {
      onUserSelect(user);
    }
  }, [userLookup, onUserSelect]);

  return (
    <Form
      form={form}
      labelCol={{ span: 2 }}
      wrapperCol={{ span: 4 }}
    >
      <Form.Item label={label} name="userId" className={styles.formItem}>
        <Select
          placeholder={placeHolder}
          disabled={!onUserSelect}
          suffixIcon={<UserOutlined className={styles.suffixIcon}/>}
          showSearch={true}
          filterOption={true}
          optionFilterProp="label"
          loading={users === undefined}
          onSelect={onSelectUserOption}
          options={userOptions}
        />
      </Form.Item>
    </Form>
  );
};

interface Option {
  label: string;
  value: string;
}

const createUserOption = (user: User): Option => ({
  label: stringifyUser(user),
  value: user.userId,
});

const stringifyUser = (user: User) => {
  if (user.lastName && user.firstName) {
    return `${user.lastName}, ${user.firstName}`;
  }
  return user.kiraId;
};
