import { Brand } from "@org-avp/avp-avengers-project-service-api";

export enum TargetMaterialMode {
  DEFAULT = "DEFAULT",
  COLOR_FAMILY = "COLOR_FAMILY",
}

export const getTargetMaterialMode: (brand: Brand) => TargetMaterialMode = (brand: Brand) => {
  switch (brand) {
    case Brand.BENTLEY:
    case Brand.LAMBORGHINI:
      return TargetMaterialMode.COLOR_FAMILY;
    default:
      return TargetMaterialMode.DEFAULT;
  }
};
