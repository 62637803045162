import { clearStatusByTask, selectFinishedTasks } from "@org-avp/avp-avengers-ui-framework-geometry-file";
import { ProjectState } from "@org-avp/avp-avengers-ui-framework-services/project";
import { useDebounced } from "@org-avp/avp-avengers-ui-framework-util";
import { notification } from "antd";
import { FC, useEffect } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../app/hooks";

export type NotificationHandlerProps = { projectState: ProjectState };
type UseNotificationsProps = NotificationHandlerProps & { notificationApi: ReturnType<typeof notification.useNotification>[0] };
export const NotificationHandler: FC<NotificationHandlerProps> = ({ projectState }) => {
  const [notificationApi, contextHolder] = notification.useNotification({
    stack: { threshold: 3 },
  });

  usePendingGeometryErrorNotifications({ notificationApi, projectState });
  return contextHolder;
};

const pendingGeometryErrorLimit = 5;
const usePendingGeometryErrorNotifications = ({ notificationApi }: UseNotificationsProps) => {
  const finishedTasks = useDebounced(useSelector(selectFinishedTasks), 500);
  const appDispatch = useAppDispatch();
  useEffect(
    () => {
      if (Object.entries(finishedTasks).length > 0) {
        Object.keys(finishedTasks).forEach((taskId) => {
          appDispatch(clearStatusByTask(taskId));
        });

        Object.entries(finishedTasks).forEach(([taskId, geometries]) => {
          const fileStatusList = Object.values(geometries).flatMap((it) => Object.values(it));
          const type = fileStatusList?.[0]?.type;

          const errorFileNames = Object.values(geometries).flatMap((it) => Object.entries(it))
            .filter(([, file]) => file.status === "ERROR")
            .map(([fileName]) => fileName);

          if (errorFileNames.length > 0) {
            const notificationContent = (
              <div>
                <p>
                  <Trans
                    ns="app"
                    i18nKey={`notifications.pendingGeometries.error.description.${type}`}
                    values={{ count: errorFileNames.length }}
                  />
                </p>
                <ul>
                  {
                    errorFileNames.sort()
                      .filter((_, index) => index < pendingGeometryErrorLimit)
                      .map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))
                  }
                  {errorFileNames.length > pendingGeometryErrorLimit && (
                    <li key="more">
                      <Trans
                        ns="app"
                        i18nKey="notifications.pendingGeometries.error.description.MORE"
                        values={{ count: errorFileNames.length - pendingGeometryErrorLimit }}
                      />
                    </li>
                  )}
                </ul>
              </div>
            );

            notificationApi.error({
              key: taskId,
              message: <Trans ns="app" i18nKey={`notifications.pendingGeometries.error.title.${type}`}/>,
              description: notificationContent,
              duration: null,
            });
          } else {
            notificationApi.success({
              key: taskId,
              message: <Trans ns="app" i18nKey={`notifications.pendingGeometries.success.title.${type}`}/>,
              description: (
                <div>
                  <p>
                    <Trans
                      ns="app"
                      i18nKey={`notifications.pendingGeometries.success.description.${type}`}
                      values={{ count: fileStatusList.length }}
                    />
                  </p>
                </div>
              ),
            });
          }
        });
      }
    },
    [appDispatch, finishedTasks, notificationApi],
  );
};
