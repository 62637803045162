import { Project, Subproject } from "@org-avp/avp-avengers-project-service-api";
import { RegisteredView, selectModularViews } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { DockContext, DragDropDiv, DragHandler, DragState, TabData, TabGroup } from "rc-dock";
import { Filter } from "rc-dock/es/Algorithm";
import { RefObject } from "react";
import { TabActions } from "../components/dock-enhancements/tab-actions/TabActions";
import { TabTitle } from "../components/dock-enhancements/tab-title/TabTitle";
import { ViewWrapper } from "../components/dock-enhancements/view-wrapper/ViewWrapper";

export const DEFAULT_GROUP_KEY = "default";
export const DEFAULT_GROUP: TabGroup = {
  floatable: true,
  maximizable: false,
  panelExtra: (panel) => {
    const viewRecord = selectModularViews();
    const view = viewRecord[panel.activeId ?? ""];
    return <TabActions view={view}/>;
  },
};

let retrieveDockContext: () => DockContext | null = () => null;
export const setDockContext = (dockContext: DockContext | null) => {
  retrieveDockContext = () => dockContext;
};

export function createTabData(view: RegisteredView): TabData {
  return {
    id: view.registeredViewKey,
    title: <TabTitle view={view}/>,
    content: <ViewWrapper view={view}/>,
    group: DEFAULT_GROUP_KEY,
    cached: true,
  };
}

export function startTabDrag(view: RegisteredView, ref: RefObject<DragDropDiv>): DragHandler {
  return (dragState: DragState) => {
    withNonNull(ref.current, (dragDropDiv) => {
      withNonNull(retrieveDockContext(), (dockContext) => {
        dragState.setData({ tab: createTabData(view) }, dockContext.getDockId());
        dragState.startDrag(dragDropDiv.element, dragDropDiv.element);
      });
    });
  };
}

export function undockViewIntoPopup(view: RegisteredView, project: Project, subproject: Subproject) {
  const navigateTo = replaceSubprojectValues(replaceProjectValues(view.navigateTo, project), subproject);
  window.open(`/${navigateTo}`, "_blank", "popup");
  removeViewFromLayout(view);
}

export function removeViewFromLayout(view: RegisteredView) {
  withNonNull(retrieveDockContext(), (dockContext) => {
    const tabData = dockContext.find(view.registeredViewKey, Filter.AnyTab) as TabData;
    withNonNull(tabData, (it) => dockContext.dockMove(it, null, "remove"));
  });
}

export function addFloatingView(view: RegisteredView) {
  withNonNull(retrieveDockContext(), (dockContext) => {
    dockContext.dockMove(createTabData(view), null, "float", { left: 32, top: 32, width: 500, height: 500 });
  });
}

export function showTabView(view: RegisteredView) {
  withNonNull(retrieveDockContext(), (dockContext) => {
    dockContext.updateTab(view.registeredViewKey, null, true);
  });
}

function replaceProjectValues(navigateTo: string, project: Project): string {
  navigateTo = navigateTo.replace(":projectId", project.projectId);
  navigateTo = navigateTo.replace(":projectKey", project.projectKey);
  navigateTo = navigateTo.replace(":brand", project.brand);
  return navigateTo;
}

function replaceSubprojectValues(navigateTo: string, subproject: Subproject): string {
  navigateTo = navigateTo.replace(":subprojectId", subproject.subprojectId);
  navigateTo = navigateTo.replace(":tmmId", subproject.tmmId);
  navigateTo = navigateTo.replace(":modelYear", subproject.modelYear.toString());
  navigateTo = navigateTo.replace(":mbvKeys", subproject.mbvKeys.toString());
  navigateTo = navigateTo.replace(":template", subproject.template);
  return navigateTo;
}

function withNonNull<T>(value: T | null | undefined, consumer: (val: T) => void) {
  if (value !== undefined && value !== null) {
    consumer(value);
  }
}
