import { AvengersTheme, RegisteredView, selectTheme } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { Options } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { FC, useMemo } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import styles from "./ViewWrapper.module.less";

export interface ViewWrapperProps {
  view: RegisteredView;
}

export const ViewWrapper: FC<ViewWrapperProps> = (props) => {
  const theme = useAppSelector(selectTheme);
  const options: Options = useMemo(
    () => ({
      className: getScrollbarClassName(theme),
    }),
    [theme],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}/>
      <OverlayScrollbarsComponent className={styles.view} options={options}>
        {props.view.el}
      </OverlayScrollbarsComponent>
    </div>
  );
};

function getScrollbarClassName(theme: AvengersTheme): string | undefined {
  switch (theme) {
    case AvengersTheme.LIGHT:
      return "os-theme-dark";
    case AvengersTheme.DARK:
      return "os-theme-light";
  }

  return undefined;
}
