import { RegisteredView, selectFullSizeViews, selectModularViews } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { createRoutes } from "@org-avp/avp-avengers-ui-framework-oidc";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createBrowserRouter, redirect, useRouteError } from "react-router-dom";
import logo from "../../public/abinaut.png";
import { AppShell } from "../components/app-shell/AppShell";
import { HomeView } from "../components/home-view/HomeView";
import { environment } from "../environment";

const RouteError: FC = () => {
  useTranslation();
  const error = useRouteError();
  return (
    <div>
      <p><Trans ns="app" i18nKey="error"/></p>
      <p>{JSON.stringify(error)}</p>
    </div>
  );
};

const createRoutesFromViews = (views: RegisteredView[]) => views.map((view) => ({
  path: view.routerPath,
  element: view.el,
}));

const createRoutesFromModularViews = (views: RegisteredView[]) => views.map((view) => ({
  path: view.routerPath,
  element: (
    <div style={{ height: "100vh" }}>
      {view.el}
    </div>
  ),
}));

export const createRouter = () => {
  return createBrowserRouter(
    createRoutes({
      errorElement: <RouteError/>,
      loginLogoElement: <img src={logo} alt="AVEnGeRS logo"/>,
      hrefDataProtectionStatement: environment.NX_IMPRINT_DATAPROTECTIONSTATEMENT_URL,
      hrefVWImprint: environment.NX_IMPRINT_VWAG_URL,
      unguardedRoutes: [
        {
          path: "/load-subproject/:projectId/:subprojectId",
          loader: ({ params: { projectId, subprojectId } }) => {
            return redirect(`/app/project/${projectId}/${subprojectId}`);
          },
        },
      ],
      guardedRoutes: [
        {
          path: "/",
          element: <AppShell/>,
          children: [
            ...createRoutesFromViews(Object.values(selectFullSizeViews())),
            { path: "/", element: <HomeView/> },
          ],
        },
        {
          children: [
            ...createRoutesFromModularViews(Object.values(selectModularViews())),
          ],
        },
      ],
    }),
  );
};
