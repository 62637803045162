import {
  errorResponseMiddleware,
  errorResponseReducer,
  errorResponseSliceName,
  errorSuppressionReducer,
  errorSuppressionSliceName,
} from "@org-avp/avp-avengers-ui-framework-error-response";
import { languageReducer, languageSliceName } from "@org-avp/avp-avengers-ui-framework-i18n";
import {
  authoringSystemReducer,
  authoringSystemSliceName,
  overlayReducer,
  overlaySliceName,
  selectReducers,
  selectStateSyncWhitelist,
  selectStoreMiddlewares,
  themeSliceName,
  themeSliceReducer,
  viewsToOpenReducer,
  viewsToOpenSliceName,
} from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { oidcReducer, oidcSliceName } from "@org-avp/avp-avengers-ui-framework-oidc";
import {
  samGeometryReducer,
  samGeometrySliceName,
  samMaterialInfoReducer,
  samMaterialInfoSliceName,
  samPartlistReducer,
  samPartlistSliceName,
  samProjectStructureReducer,
  samProjectStructureSliceName,
  samQaReducer,
  samQaSliceName,
  samRepresentationReducer,
  samRepresentationSliceName,
  samTemplateReducer,
  samTemplateSliceName,
  samTmmReducer,
  samTmmSliceName,
} from "@org-avp/avp-avengers-ui-framework-sam";
import { projectReducer, projectSliceName } from "@org-avp/avp-avengers-ui-framework-services/project";
import {
  ActionFromReducersMapObject,
  AnyAction,
  combineReducers,
  configureStore,
  Dispatch,
  StateFromReducersMapObject,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from "redux-state-sync";
import layoutReducer, { layoutSliceName } from "../modules/app-module/slices/layoutSlice";
import { asConnectorApi } from "../services/as-connector-service";
import { electronClientApi } from "../services/electron-client-service";
import { menuOptionsReducer, menuOptionsSliceName } from "../slices/menuOptionsSlice";

export type RootState = StateFromReducersMapObject<ReturnType<typeof createAppReducers>>;
export type AppDispatch = Dispatch<ActionFromReducersMapObject<ReturnType<typeof createAppReducers>>> & ThunkDispatch<RootState, unknown, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

// Include state names of state slice that should be synced as keys and the reducer actions that should be synced as values
const stateSyncWhitelist: Record<string, string[]> = {
  ...selectStateSyncWhitelist(),
  [languageSliceName]: ["setLanguage"],
  [samGeometrySliceName]: ["setGeometrySelection"],
  [themeSliceName]: ["setTheme"],
};
const stateSyncMiddleware = createStateSyncMiddleware({
  prepareState: (state) => {
    const stateSyncWhitelistKeys = Object.keys(stateSyncWhitelist);
    return Object.fromEntries(Object.entries(state)
      .filter(([key]) => stateSyncWhitelistKeys.includes(key)),
    );
  },
  whitelist: Object.entries(stateSyncWhitelist).reduce((acc, [key, val]) => acc.concat(key + "/" + val), [] as string[]),
});

const createAppReducers = () => ({
  ...selectReducers(),
  [errorResponseSliceName]: errorResponseReducer,
  [errorSuppressionSliceName]: errorSuppressionReducer,
  [authoringSystemSliceName]: authoringSystemReducer,
  [languageSliceName]: languageReducer,
  [layoutSliceName]: layoutReducer,
  [menuOptionsSliceName]: menuOptionsReducer,
  [projectSliceName]: projectReducer,
  [oidcSliceName]: oidcReducer,
  [overlaySliceName]: overlayReducer,
  [themeSliceName]: themeSliceReducer,
  [samGeometrySliceName]: samGeometryReducer,
  [samMaterialInfoSliceName]: samMaterialInfoReducer,
  [samPartlistSliceName]: samPartlistReducer,
  [samProjectStructureSliceName]: samProjectStructureReducer,
  [samQaSliceName]: samQaReducer,
  [samRepresentationSliceName]: samRepresentationReducer,
  [samTemplateSliceName]: samTemplateReducer,
  [samTmmSliceName]: samTmmReducer,
  [viewsToOpenSliceName]: viewsToOpenReducer,
  [asConnectorApi.reducerPath]: asConnectorApi.reducer,
  [electronClientApi.reducerPath]: electronClientApi.reducer,
});

export const createStore = () => {
  const store = configureStore({
    devTools: new URLSearchParams(window.location.search).has("enableDevTools"),
    reducer: withReduxStateSync(combineReducers<RootState>(createAppReducers())),
    middleware: (getDefaultMiddleware) => Array.from(new Set([
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
      ...selectStoreMiddlewares(),
      errorResponseMiddleware,
      stateSyncMiddleware,
      asConnectorApi.middleware,
      electronClientApi.middleware,
    ])),
  });

  initStateWithPrevTab(store);
  return store;
};
