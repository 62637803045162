import { Brand } from "@org-avp/avp-avengers-project-service-api";
import { CrudOp, roleAssignmentAuthScope, useAuthHelper } from "@org-avp/avp-avengers-ui-framework-oidc";
import { userApi } from "@org-avp/avp-avengers-ui-framework-services/user";
import { Tabs, TabsProps } from "antd";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RoleAssignmentTab } from "./role-assignment-tab/RoleAssignmentTab";
import styles from "./RoleManagementView.module.less";

const brands: Brand[] = Object.values(Brand);

export const RoleManagementView: FC = () => {
  useTranslation();
  return (
    <div className={styles.container}>
      <h1><Trans ns="app" i18nKey="roleManagementView.title"/></h1>
      <Tabs className={styles.tabContainer} {...useTabsPropsHelper()}/>
    </div>
  );
};

function useTabsPropsHelper(): TabsProps {
  const { evaluate } = useAuthHelper(roleAssignmentAuthScope);

  const { data: userList } = userApi.useGetAllUsersQuery();

  const brandAuths = useMemo(() => buildAuthorizationForAllBrands(evaluate), [evaluate]);

  const defaultActiveKey = useMemo(() => brands.find((brand) => brandAuths.get(brand)), [brandAuths]);

  const items: TabsProps["items"] = useMemo(() => {
    return brands.map((brand) => ({
      key: brand,
      label: <Trans ns="common" i18nKey={`brands.${brand}`}/>,
      disabled: !brandAuths.get(brand),
      children: <RoleAssignmentTab brand={brand} users={userList?.users}/>,
      className: styles.tabContent,
    }));
  }, [brandAuths, userList?.users]);

  return useMemo((): TabsProps => ({
    defaultActiveKey: defaultActiveKey,
    items,
  }), [defaultActiveKey, items]);
}

function buildAuthorizationForAllBrands(evaluate: (crudOp: CrudOp, brand: string) => boolean): Map<Brand, boolean> {
  return new Map(brands.map((brand) => [brand, evaluate(CrudOp.READ, brand)]));
}
