import { RegisteredView } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TabTitle.module.less";

export interface TabTitleProps {
  view: RegisteredView;
}

export const TabTitle: FC<TabTitleProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <span className={styles.title}>{t(props.view.displayName)}</span>
    </>
  );
};
