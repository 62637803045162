import { FC } from "react";
import styles from "./ErrorResponseDialog.module.less";

export const TitleMessage: FC<{ title: string; message: string }> = ({ title, message }) => {
  return (
    <>
      <TextBlock key="title" text={title} className={styles.title}/>
      <TextBlock key="message" text={message} className={styles.message}/>
    </>
  );
};

const TextBlock: FC<{ text: string; className: string }> = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};
