import { Brand } from "@org-avp/avp-avengers-project-service-api";
import { AvengersRole, CrudOp, roleAssignmentAuthScope, useAuthHelper } from "@org-avp/avp-avengers-ui-framework-oidc";
import { ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectUserRoleRow } from "../use-role-assignment-helper";
import { RoleColDef } from "./use-roleAssignment-columnDefs";

export const useColDefRoles = (
  roles: AvengersRole[],
  addEditedRow: (r: ProjectUserRoleRow) => void,
  brand: Brand,
): RoleColDef[] => {
  const { t } = useTranslation();
  const { evaluate } = useAuthHelper(roleAssignmentAuthScope);

  const valueGetter = useCallback((role: AvengersRole) => ({ data }: ValueGetterParams<ProjectUserRoleRow>) => data?.roles?.includes(role), []);

  const valueSetter = useCallback((role: AvengersRole) => ({ data, newValue }: ValueSetterParams<ProjectUserRoleRow>) => {
    if (newValue !== data.roles.includes(role)) {
      data.roles = newValue ? [...data.roles, role] : data.roles.filter((r: string) => r !== role);
      addEditedRow(data);
      return true;
    } else return false;
  }, [addEditedRow]);

  return useMemo(() => roles.map((role) => ({
    colId: `role_${role}`,
    headerName: t(`app:roleManagementView.roles.${role}`) as string,
    editable: evaluate(CrudOp.UPDATE, brand),
    sortable: false,
    valueGetter: valueGetter(role),
    valueSetter: valueSetter(role),
    cellRenderer: "agCheckboxCellRenderer",
    cellEditor: "agCheckboxCellEditor",
  })), [brand, evaluate, roles, t, valueGetter, valueSetter]);
};
