import { CrudOp, roleAssignmentOverviewAuthScope, useAuthHelper } from "@org-avp/avp-avengers-ui-framework-oidc";
import { Form, Input, Modal, ModalProps, Space } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function normalizeKiraId(kiraId: string): string {
  return kiraId.toLowerCase();
}

type FormFieldName = "kiraIdInputField" | "lastNameInputField" | "firstNameInputField";

type FormFieldValues = Record<FormFieldName, string>;

interface FormData {
  [key: string]: string;
}

export type AddUserData = FormFieldValues & FormData;

const initialValues: AddUserData = {
  kiraIdInputField: "",
  lastNameInputField: "",
  firstNameInputField: "",
};

interface AddUserDialogProps {
  open: boolean;

  existingUsersKiraIds: string[];

  onClose(user?: AddUserData): void;
}

export const AddUserDialog: FC<AddUserDialogProps> = ({ open, existingUsersKiraIds, onClose }) => {
  useTranslation();
  const { evaluate } = useAuthHelper(roleAssignmentOverviewAuthScope);

  const [okButtonProps, setOkButtonProps] = useState<ModalProps["okButtonProps"]>({});
  const setIsFormValid = useCallback((isFormValid: boolean) => {
    setOkButtonProps((props) => ({ ...props, disabled: !isFormValid || !evaluate(CrudOp.CREATE) }));
  }, [evaluate]);
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, formValues, setIsFormValid]);

  const onOkClick = useCallback(() => {
    const formFieldData: AddUserData = { ...initialValues };
    Object.keys(initialValues).forEach((fieldName) => {
      formFieldData[fieldName] = form.getFieldValue(fieldName);
    });
    onClose(formFieldData);
  }, [form, onClose]);

  const onCancel = useCallback(() => onClose(), [onClose]);

  const afterOpenChange = useCallback((open: boolean) => {
    if (open) {
      form.validateFields()
        .catch(() => undefined)
        .finally(() => form.getFieldInstance("kiraIdInputField")?.focus());
    } else {
      form.resetFields();
    }
  }, [form]);

  return (
    <Modal
      open={open}
      title={<Trans ns="app" i18nKey="roleManagementView.dialog.addUser.title"/>}
      okText={<Trans ns="common" i18nKey="create"/>}
      okButtonProps={okButtonProps}
      onOk={onOkClick}
      cancelText={<Trans ns="common" i18nKey="cancel"/>}
      onCancel={onCancel}
      afterOpenChange={afterOpenChange}
      destroyOnClose={true}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          colon={false}
          initialValues={initialValues}
        >
          <Form.Item
            name="kiraIdInputField"
            label={<Trans ns="app" i18nKey="roleManagementView.field.kiraId.label"/>}
            required
            hasFeedback
            rules={[
              {
                required: true,
                len: 7,
                message: <Trans ns="app" i18nKey="roleManagementView.field.kiraId.validation.length"/>,
              },
              {
                pattern: /^[a-z0-9]+$/,
                message: <Trans ns="app" i18nKey="roleManagementView.field.kiraId.validation.invalidCharacters"/>,
              },
              {
                message: <Trans ns="app" i18nKey="roleManagementView.field.kiraId.validation.alreadyExists"/>,
                validator: (_, value) => !existingUsersKiraIds.includes(value) ? Promise.resolve() : Promise.reject(),
              },
            ]}
            validateDebounce={500}
            normalize={normalizeKiraId}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            name="lastNameInputField"
            label={<Trans ns="app" i18nKey="roleManagementView.field.lastName.label"/>}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            name="firstNameInputField"
            label={<Trans ns="app" i18nKey="roleManagementView.field.firstName.label"/>}
          >
            <Input/>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
