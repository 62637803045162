import { selectTheme } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { Layout, Menu } from "antd";
import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";
import styles from "./MenuBar.module.less";
import { useMenuItemsHelper } from "./use-menu-items-helper";

export const MenuBar: FC = () => {
  const { topItems, bottomItems, collapsed, selectedKeys } = useMenuItemsHelper();
  const theme = useAppSelector(selectTheme);

  return (
    <Layout.Sider className={styles.menu} collapsed={collapsed}>
      <div className={styles.container}>
        <Menu theme={theme} mode="inline" selectedKeys={selectedKeys} items={topItems}/>
        <Menu theme={theme} mode="inline" selectedKeys={selectedKeys} items={bottomItems}/>
      </div>
    </Layout.Sider>
  );
};
