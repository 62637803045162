import { LanguageSelect } from "@org-avp/avp-avengers-ui-framework-i18n";
import { Form } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AuthoringSystemSelect } from "./item/AuthoringSystemSelect";
import { LayoutReset } from "./item/LayoutReset";
import { ThemeSelect } from "./item/ThemeSelect";

import styles from "./SettingsView.module.less";

export const SettingsView: FC = () => {
  useTranslation();

  const { t } = useTranslation("app");

  return (
    <Form layout="vertical" className={styles.settingsForm} wrapperCol={{ span: 4 }}>

      <Form.Item label={t("settingsView.languageSelector.label")}>
        <LanguageSelect/>
      </Form.Item>

      <Form.Item label={t("settingsView.themeSelector.label")}>
        <ThemeSelect/>
      </Form.Item>

      <Form.Item label={t("settingsView.authoringSystemSelector.label")}>
        <AuthoringSystemSelect/>
      </Form.Item>

      <Form.Item>
        <LayoutReset/>
      </Form.Item>
    </Form>
  );
};
