import { AuthoringSystem } from "@org-avp/avp-avengers-ui-framework-electron-api";
import { selectAuthoringSystem, setAuthoringSystem } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { Select } from "antd";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";

export const AuthoringSystemSelect = () => {
  const appDispatch = useAppDispatch();
  const authoringSystemState = useAppSelector(selectAuthoringSystem);
  const changeAuthoringSystem = (authoringSystem: AuthoringSystem) => {
    appDispatch(setAuthoringSystem(authoringSystem));
  };

  return (
    <Select<AuthoringSystem> value={authoringSystemState.authoringSystem} onChange={changeAuthoringSystem}>
      {
        Object.values(AuthoringSystem).map(
          (asOption) => (
            <Select.Option key={asOption} value={asOption}>
              <Trans ns="app" i18nKey={`settingsView.authoringSystemSelector.optionLabel.${asOption}`}/>
            </Select.Option>
          ),
        )
      }
    </Select>
  );
};
