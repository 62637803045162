import { FilterModel, IServerSideGetRowsRequest } from "ag-grid-community";

type FilterTypeBoolean = "true" | "false";
type FilterTypeNumeric = "equals" | "lessThan" | "greaterThan" | "inRange";

type ColumnFilterModelText = {
  filterType: "text";
  type: "match";
  filter: string;
};

type ColumnFilterModelEnum = {
  filterType: "set";
  values: string[];
};

type ColumnFilterModelBoolean = {
  filterType: "set";
  type: FilterTypeBoolean;
  values: string[];
};

type ColumnFilterModelNumber = {
  filterType: "number";
  type: FilterTypeNumeric;
  filter: number | null;
  filterTo: number | null;
};

type ColumnFilterModelDate = {
  filterType: "date";
  type: FilterTypeNumeric;
  dateFrom: string | null;
  dateTo: string | null;
};

type ColumnFilterModel =
  ColumnFilterModelBoolean
  | ColumnFilterModelDate
  | ColumnFilterModelEnum
  | ColumnFilterModelNumber
  | ColumnFilterModelText
  ;

type ColumnIdType<K extends string, I extends string> = { [P in K]: I; };
export type ColumnFilter<K extends string, I extends string, C extends string> = ColumnIdType<K, I> & {
  condition?: C;
  values: string[];
};

export type ColumnSort<K extends string, I extends string> = ColumnIdType<K, I> & {
  sortDir: "asc" | "desc";
};

export const convertColumnFilters = <T extends ColumnFilter<K, I, C>, K extends string, I extends string = T[K], C extends string = T["condition"]>(
  request: IServerSideGetRowsRequest,
  columnIds: I[],
  conditions: C[],
  colKey: K,
): T[] => {
  const columnFilters: T[] = [];
  const filterModel = request.filterModel as FilterModel | null;
  if (filterModel != null) {
    Object.keys(filterModel)
      .filter((it) => columnIds.includes(it as I))
      .forEach((columnId) => {
        const columnFilterModel = filterModel[columnId] as ColumnFilterModel;
        switch (columnFilterModel.filterType) {
          case "set": {
            const condition = columnFilterModel.values.length === 1 ? Object.values(conditions).find((it) => it === "equals") : undefined;
            columnFilters.push({ [colKey]: columnId, condition, values: columnFilterModel.values } as T);
            break;
          }
          case "text":
            columnFilters.push({ [colKey]: columnId, values: [columnFilterModel.filter] } as T);
            break;
          case "date": {
            const dateFrom = columnFilterModel.dateFrom ?? "";
            const dateTo = columnFilterModel.dateTo ?? "";
            const condition = Object.values(conditions).find((it) => it === columnFilterModel.type);
            columnFilters.push({ [colKey]: columnId, condition, values: [dateFrom, dateTo] } as T);
            break;
          }
          case "number": {
            const filter = columnFilterModel.filter ? `${columnFilterModel.filter}` : "";
            const filterTo = columnFilterModel.filterTo ? `${columnFilterModel.filterTo}` : "";
            const condition = Object.values(conditions).find((it) => it === columnFilterModel.type);
            columnFilters.push({ [colKey]: columnId, condition, values: [filter, filterTo] } as T);
            break;
          }
          default:
            console.warn("Column filter not supported!", columnFilterModel);
            break;
        }
      });
  }

  return columnFilters;
};

export const convertColumnSorts = <T extends ColumnSort<K, I>, K extends string, I extends string = T[K]>(
  request: IServerSideGetRowsRequest,
  columnIds: string[],
  colKey: K,
): T[] => {
  return request.sortModel
    .filter((it) => columnIds.includes(it.colId))
    .map((it) => ({
      [colKey]: it.colId,
      sortDir: it.sort,
    } as T));
};
