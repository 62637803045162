import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export const menuOptionsSliceName = "menu";
export type MenuOptionsState = {
  collapsed: boolean;
};

const persistedMenuOptions = localStorage.getItem(menuOptionsSliceName);
const persistedState = persistedMenuOptions ? JSON.parse(persistedMenuOptions) as MenuOptionsState : null;
const fallback: MenuOptionsState = {
  collapsed: true,
};
const initialState: MenuOptionsState = persistedState ?? fallback;

const menuOptionsSlice = createSlice({
  name: menuOptionsSliceName,
  initialState,
  reducers: {
    setMenuOptions(_, { payload }: PayloadAction<MenuOptionsState>) {
      persistState(payload);
      return payload;
    },
    setMenuCollapsed(state: MenuOptionsState, { payload }: PayloadAction<boolean>) {
      const next: MenuOptionsState = { ...state, collapsed: payload };
      persistState(next);
      return next;
    },
    toggleMenuCollapsed(state: MenuOptionsState) {
      const next: MenuOptionsState = { ...state, collapsed: !state.collapsed };
      persistState(next);
      return next;
    },
  },
});

export type MenuOptionsRootState = { [menuOptionsSliceName]: MenuOptionsState };

export const selectMenuOptions = (state: MenuOptionsRootState) => state[menuOptionsSliceName];
export const setMenuCollapsed = (collapsed: boolean) => (dispatch: Dispatch) => {
  dispatch(menuOptionsSlice.actions.setMenuCollapsed(collapsed));
};
export const toggleMenuCollapsed = () => (dispatch: Dispatch) => {
  dispatch(menuOptionsSlice.actions.toggleMenuCollapsed());
};

function persistState(state: MenuOptionsState) {
  localStorage.setItem(menuOptionsSliceName, JSON.stringify(state));
}

export const menuOptionsReducer = menuOptionsSlice.reducer;
