import {
  clearRegisteredViews,
  RegisteredView,
  selectModularViews,
  selectViewsToOpen,
  useRequiredProjectParam,
} from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { useAuthHelperCreator } from "@org-avp/avp-avengers-ui-framework-oidc";
import { Button, Tooltip } from "antd";
import { DragDropDiv, LayoutData } from "rc-dock";
import { Filter, find } from "rc-dock/es/Algorithm";
import { createRef, FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { LayoutState, selectLayout } from "../../../slices/layoutSlice";
import { addFloatingView, showTabView, startTabDrag } from "../../../utils/layoutHelpers";
import { LayoutProfiles } from "./layout-profiles/LayoutProfiles";
import styles from "./OperatorViewMenu.module.less";

export const OperatorViewMenu: FC = () => {
  const { t } = useTranslation();
  const views: RegisteredView[] = useMemo(() => Object.values(selectModularViews()), []);

  const layout = useAppSelector(selectLayout);

  const viewsToOpen = useSelector(selectViewsToOpen);

  const appDispatch = useAppDispatch();
  useEffect(() => {
    const viewLookup = new Map(Object.values(selectModularViews()).map((i) => [i.displayName, i]));
    if (viewsToOpen.length > 0) {
      viewsToOpen.forEach((viewToOpen) => {
        const registeredView = viewLookup.get(viewToOpen.viewDisplayName);
        if (registeredView) {
          if (!isViewInLayout(layout, registeredView))
            addFloatingView(registeredView);
          else
            showTabView(registeredView);
        }
      });
      appDispatch(clearRegisteredViews());
    }
  }, [appDispatch, layout, viewsToOpen]);
  const createFloatTab = useCallback((view: RegisteredView) => () => addFloatingView(view), []);

  const brand = useRequiredProjectParam("brand");
  const projectId = useRequiredProjectParam("projectId");
  const authDiffValues = useMemo(
    () => ({ brand, project: projectId }),
    [brand, projectId],
  );

  const authHelperCreator = useAuthHelperCreator();
  const viewIcons = useMemo(
    () => views.map((view) => {
      const viewInLayout = isViewInLayout(layout, view);
      const unauthorizedForView = !view.isAuthorized(authHelperCreator, authDiffValues);
      const tooltipText = t(view.displayName)
        + (unauthorizedForView ? " - " + t("app:operatorView.notAuthorized") : "")
        + (viewInLayout ? " - " + t("app:operatorView.alreadyInLayout") : "");

      const disabled = viewInLayout || unauthorizedForView;
      const button = (
        <Tooltip title={tooltipText} placement="bottomLeft" key={view.registeredViewKey}>
          <Button icon={view.menuDefinition.menuIconDef} type="text" disabled={disabled} onClick={createFloatTab(view)}/>
        </Tooltip>
      );

      if (viewInLayout || unauthorizedForView) {
        return button;
      }

      const dragRef = createRef<DragDropDiv>();
      return (
        <DragDropDiv ref={dragRef} key={view.registeredViewKey} onDragStartT={startTabDrag(view, dragRef)}>
          {button}
        </DragDropDiv>
      );
    }),
    [authDiffValues, authHelperCreator, createFloatTab, layout, t, views],
  );

  return (
    <div className={styles.container}>
      {viewIcons}
      <div className={styles.push}/>
      <LayoutProfiles/>
    </div>
  );
};

export function isViewInLayout(layout: LayoutState, view: RegisteredView): boolean {
  const tabData = find(layout as LayoutData, view.registeredViewKey, Filter.AnyTab);
  return tabData != null;
}
