import { prepareHeaders } from "@org-avp/avp-avengers-ui-framework-services";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../environment";

export type DownloadUrlRequest = { clientVersion: string; webIdentityToken: string };
export type DownloadUrlResponse = { downloadUrl: string };

export const electronClientApiKey = "electronClientApi" as const;
export const electronClientApi = createApi({
  reducerPath: electronClientApiKey,
  baseQuery: fetchBaseQuery({
    baseUrl: environment.NX_ELECTRON_CLIENT_DOWNLOAD_URL,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    // disable eslint rule: the second type argument indicates the types of the arguments for the "query" function, which has no args in this case
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getVersionList: builder.query<string[], void>({
      query: () => ({
        method: "GET", url: "",
        responseHandler: (response) => {
          return Promise.resolve(response.json());
        },
      }),
    }),
    getDownloadUrl: builder.mutation<DownloadUrlResponse, DownloadUrlRequest>({
      query: (requestBody) => ({
        method: "POST", url: "", body: requestBody,
        responseHandler: (response) => {
          return Promise.resolve(response.json());
        },
      }),
    }),
  }),
});

export const {
  useGetVersionListQuery,
  useGetDownloadUrlMutation,
} = electronClientApi;
