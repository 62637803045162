export interface Environment {
  NX_AS_CONNECTOR_DOWNLOAD_URL: string;
  NX_ELECTRON_CLIENT_DOWNLOAD_URL?: string;
  NX_IMPRINT_VWAG_URL?: string;
  NX_IMPRINT_DATAPROTECTIONSTATEMENT_URL?: string;
  NX_AGGRID_LICENSE_KEY: string;
  NX_ENV_NAME: string;
}

declare const environment: Environment;
const exportEnv = environment;
export { exportEnv as environment };
