import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { TargetMaterialMode } from "../model/targetMaterialMode";

export const targetManagerViewOptionsSliceName = "targetManger";

export enum TargetManagerColumn {
  COLOR = "COLOR",
  COLOR_CODE = "COLOR_CODE",
  COLOR_FAMILY = "COLOR_FAMILY",
  COLOR_FAMILY_DESCRIPTION = "COLOR_FAMILY_DESCRIPTION",
  GRAIN_CODE = "GRAIN_CODE",
  GROUP = "GROUP",
  INDEX = "INDEX",
  PIN = "PIN",
  SPECIFIC = "SPECIFIC",
  TMM = "TMM",
  ZONE = "ZONE",
}

export const targetManagerColumnIds: Record<TargetMaterialMode, string[]> = {
  DEFAULT: [
    TargetManagerColumn.PIN,
    TargetManagerColumn.TMM,
    TargetManagerColumn.GROUP,
    TargetManagerColumn.GRAIN_CODE,
    TargetManagerColumn.COLOR_CODE,
    TargetManagerColumn.ZONE,
    TargetManagerColumn.INDEX,
    TargetManagerColumn.COLOR,
  ],
  COLOR_FAMILY: [
    TargetManagerColumn.PIN,
    TargetManagerColumn.TMM,
    TargetManagerColumn.GROUP,
    TargetManagerColumn.COLOR_FAMILY,
    TargetManagerColumn.ZONE,
    TargetManagerColumn.COLOR_FAMILY_DESCRIPTION,
    TargetManagerColumn.SPECIFIC,
    TargetManagerColumn.COLOR,
  ],
};

export type TargetMangerViewOptionsState = {
  columns: Record<TargetMaterialMode, string[]>;
};

const persistedTargetManagerViewOptions = localStorage.getItem(targetManagerViewOptionsSliceName);
const persistedState = persistedTargetManagerViewOptions ? JSON.parse(persistedTargetManagerViewOptions) as TargetMangerViewOptionsState : null;
const fallback: TargetMangerViewOptionsState = {
  columns: targetManagerColumnIds,
};
const initialState: TargetMangerViewOptionsState = persistedState ?? fallback;
// if columns does not exist, set default fallback value
if (initialState.columns === undefined || initialState.columns.DEFAULT.length === 0 || initialState.columns.COLOR_FAMILY.length === 0) {
  if (initialState.columns === undefined) {
    initialState.columns = fallback.columns;
  } else {
    if (initialState.columns.DEFAULT.length === 0) {
      initialState.columns.DEFAULT = fallback.columns.DEFAULT;
    }
    if (initialState.columns.COLOR_FAMILY.length === 0) {
      initialState.columns.COLOR_FAMILY = fallback.columns.COLOR_FAMILY;
    }
  }
}

const targetManagerViewOptionsSlice = createSlice({
  name: targetManagerViewOptionsSliceName,
  initialState,
  reducers: {
    setTargetManagerViewColumns(state: TargetMangerViewOptionsState, action: PayloadAction<[TargetMaterialMode, string[]]>) {
      const next: TargetMangerViewOptionsState = { ...state, columns: { ...state.columns, ...({ [action.payload[0]]: action.payload[1] }) } };
      persistState(next);
      return next;
    },
  },
});

export type TargetManagerViewOptionsRootState = { [targetManagerViewOptionsSliceName]: TargetMangerViewOptionsState };
export const selectTargetManagerViewOptions = (state: TargetManagerViewOptionsRootState) => state[targetManagerViewOptionsSliceName];
export const setTargetManagerViewColumns = (targetMaterialMode: TargetMaterialMode, columns: string[]) => (dispatch: Dispatch) => {
  dispatch(targetManagerViewOptionsSlice.actions.setTargetManagerViewColumns([targetMaterialMode, columns]));
};

function persistState(state: TargetMangerViewOptionsState) {
  localStorage.setItem(targetManagerViewOptionsSliceName, JSON.stringify(state));
}

export const targetManagerViewOptionsReducer = targetManagerViewOptionsSlice.reducer;
