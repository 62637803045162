import { Layout } from "antd";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import styles from "./AppShell.module.less";
import { ErrorResponseDialog } from "./error-response-dialog/ErrorResponseDialog";
import { MenuBar } from "./menu-bar/MenuBar";
import { OverlayView } from "./overlay/OverlayView";
import { TitleBar } from "./title-bar/TitleBar";

export const AppShell: FC = () => {
  return (
    <Layout className={styles.layout}>
      <Layout.Header className={styles.header}>
        <TitleBar showLogout={true} showUserInfo={true}/>
      </Layout.Header>
      <Layout>
        <MenuBar/>
        <Layout.Content className={styles.content}>
          <Outlet/>
          <OverlayView/>
        </Layout.Content>
      </Layout>
      <ErrorResponseDialog/>
    </Layout>
  );
};
