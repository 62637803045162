import { dndHandleColumnId } from "@org-avp/avp-avengers-ui-framework-components";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export const tmmViewOptionsSliceName = "tmm";

export enum TmmViewColumn {
  ADI = "adi",
  INDEX = "index",
  LABEL = "label",
  MATERIAL_CODE = "materialCode",
  MATERIAL_TYPE = "materialType",
  TARGET = "target",
  TRIGGER = "trigger",
  VALIDITY = "validity",
}

export const tmmViewColumnIds = [
  dndHandleColumnId,
  TmmViewColumn.TARGET,
  TmmViewColumn.MATERIAL_CODE,
  TmmViewColumn.ADI,
  TmmViewColumn.VALIDITY,
  TmmViewColumn.TRIGGER,
  TmmViewColumn.LABEL,
  TmmViewColumn.MATERIAL_TYPE,
  TmmViewColumn.INDEX,
];
export type TmmViewOptionsState = {
  columns: string[];
};

const persistedTmmViewOptions = localStorage.getItem(tmmViewOptionsSliceName);
const persistedState = persistedTmmViewOptions ? JSON.parse(persistedTmmViewOptions) as TmmViewOptionsState : null;
const fallback: TmmViewOptionsState = {
  columns: tmmViewColumnIds,
};
const initialState: TmmViewOptionsState = persistedState ?? fallback;
// if columns does not exist, set default fallback value
if (initialState.columns === undefined || initialState.columns.length === 0) {
  initialState.columns = fallback.columns;
}

const tmmViewOptionsSlice = createSlice({
  name: tmmViewOptionsSliceName,
  initialState,
  reducers: {
    setTmmViewColumns(state: TmmViewOptionsState, action: PayloadAction<string[]>) {
      const next: TmmViewOptionsState = { ...state, columns: action.payload };
      persistState(next);
      return next;
    },
  },
});

export type TmmViewOptionsRootState = { [tmmViewOptionsSliceName]: TmmViewOptionsState };
export const selectTmmViewOptions = (state: TmmViewOptionsRootState) => state[tmmViewOptionsSliceName];
export const setTmmViewColumns = (columns: string[]) => (dispatch: Dispatch) => {
  dispatch(tmmViewOptionsSlice.actions.setTmmViewColumns(columns));
};

function persistState(state: TmmViewOptionsState) {
  localStorage.setItem(tmmViewOptionsSliceName, JSON.stringify(state));
}

export const tmmViewOptionsReducer = tmmViewOptionsSlice.reducer;
