import { RightOutlined } from "@ant-design/icons";
import { useCognitoIdToken } from "@org-avp/avp-avengers-ui-framework-cognito-identity";
import { runsInsideElectron } from "@org-avp/avp-avengers-ui-framework-electron-api";
import { useInvokeElectronAPI } from "@org-avp/avp-avengers-ui-framework-util";
import { FC, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { environment } from "../../environment";
import { useGetDownloadUrlMutation, useGetVersionListQuery } from "../../services/electron-client-service";
import { AsConnectorManager } from "./as-connector/AsConnectorManager";
import styles from "./HomeView.module.less";

export const HomeView: FC = () => {
  useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.homeHeadline}>
        <Trans ns="app" i18nKey="homeView.title"/>
      </div>
      <AsConnectorManager/>
      <div className={styles.columnContainer}>
        {environment.NX_ELECTRON_CLIENT_DOWNLOAD_URL && <ClientDownload/>}
      </div>
    </div>
  );
};

const ClientDownload: FC = () => {
  useTranslation();
  const cognitoIdToken = useCognitoIdToken();
  const { data: clientVersions } = useGetVersionListQuery();
  return (
    <div className={styles.clientDownload}>
      <div className={styles.headline}><Trans ns="app" i18nKey="homeView.clientDownload.title"/></div>
      {(!clientVersions || !cognitoIdToken) && (
        <span>
          <Trans ns="common" i18nKey="loading"/>
          ...
        </span>
      )}
      {clientVersions && cognitoIdToken && <ClientDownloadContent {...{ clientVersions, cognitoIdToken }}/>}
    </div>
  );
};

interface ClientDownloadContentProps {
  clientVersions: string[];
  cognitoIdToken: string;
}

const ARCHIVE_DEFAULT_LENGTH = 3;
const ClientDownloadContent: FC<ClientDownloadContentProps> = ({ clientVersions, cognitoIdToken }) => {
  const [archiveUpperLimit, setArchiveUpperLimit] = useState(ARCHIVE_DEFAULT_LENGTH);
  const [getDownloadUrl, { data: downloadUrlResponse, reset }] = useGetDownloadUrlMutation();
  const initiateDownload = useCallback(
    (clientVersion: string) => getDownloadUrl({ clientVersion, webIdentityToken: cognitoIdToken }),
    [cognitoIdToken, getDownloadUrl],
  );
  useEffect(
    () => {
      if (downloadUrlResponse !== null && downloadUrlResponse !== undefined) {
        window.open(downloadUrlResponse.downloadUrl, "_blank");
        reset();
      }
    },
    [downloadUrlResponse, reset],
  );

  const insideElectron = runsInsideElectron();
  const currentClientVersion = useInvokeElectronAPI("getClientVersion");
  return (
    <>
      {/* latest section */}
      {insideElectron && currentClientVersion === clientVersions[0] && (
        <div className={styles.noUpdateHint}>
          <Trans ns="app" i18nKey="homeView.clientDownload.latest.noUpdateAvailable"/>
        </div>
      )}
      {insideElectron && currentClientVersion !== clientVersions[0] && (
        <button className={styles.downloadButton} onClick={() => initiateDownload(clientVersions[0])}>
          <Trans ns="app" i18nKey="homeView.clientDownload.latest.updateAvailable"/>
          <RightOutlined/>
        </button>
      )}
      {!insideElectron && (
        <button className={styles.downloadButton} onClick={() => initiateDownload(clientVersions[0])}>
          <Trans ns="app" i18nKey="homeView.clientDownload.latest.download"/>
          <RightOutlined/>
        </button>
      )}
      {/* archive section */}
      {clientVersions.length > 1 && (
        <div className={styles.versionArchive}>
          <div className={styles.subHeadline}>
            <Trans ns="app" i18nKey="homeView.clientDownload.archive.title"/>
          </div>
          {clientVersions.map((clientVersion, index) => (
            index === 0 || index > archiveUpperLimit
              ? <span key={clientVersion} style={{ display: "none" }}></span>
              : (
                  <button key={clientVersion} className={styles.downloadButton} onClick={() => initiateDownload(clientVersion)}>
                    <Trans ns="app" i18nKey="homeView.clientDownload.archive.download" values={{ version: clientVersion }}/>
                    <RightOutlined/>
                  </button>
                )
          ))}
          {clientVersions.length - 1 > ARCHIVE_DEFAULT_LENGTH && archiveUpperLimit === ARCHIVE_DEFAULT_LENGTH && (
            <div className={styles.expandHint} onClick={() => setArchiveUpperLimit(Number.POSITIVE_INFINITY)}>
              <Trans ns="app" i18nKey="homeView.clientDownload.archive.showAll"/>
            </div>
          )}
          {archiveUpperLimit > ARCHIVE_DEFAULT_LENGTH && (
            <div className={styles.expandHint} onClick={() => setArchiveUpperLimit(ARCHIVE_DEFAULT_LENGTH)}>
              <Trans ns="app" i18nKey="homeView.clientDownload.archive.hideOld"/>
            </div>
          )}
        </div>
      )}
    </>
  );
};
