import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CharLayoutProfileIcon } from "./CharLayoutProfileIcon";

export interface LayoutProfileButtonProps {
  layoutProfileIndex: number;
  name: string;
  applyLayoutProfile: (layoutProfileIndex: number) => void;
  saveLayoutProfile: (layoutProfileIndex: number) => void;
  editLayoutProfile: (layoutProfileIndex: number) => void;
  deleteLayoutProfile: (layoutProfileIndex: number) => void;
}

enum ContextMenuAction {
  SAVE = "save",
  EDIT = "edit",
  DELETE = "delete",
}

export const LayoutProfileButton: FC<LayoutProfileButtonProps> = (
  {
    layoutProfileIndex,
    name,
    applyLayoutProfile,
    saveLayoutProfile,
    editLayoutProfile,
    deleteLayoutProfile,
  },
) => {
  const { t } = useTranslation("common");

  const onApplyLayoutProfile = useCallback(() => {
    applyLayoutProfile(layoutProfileIndex);
  }, [applyLayoutProfile, layoutProfileIndex]);

  const onContextMenuAction = useCallback((action: ContextMenuAction) => {
    switch (action) {
      case ContextMenuAction.SAVE:
        saveLayoutProfile(layoutProfileIndex);
        break;
      case ContextMenuAction.EDIT:
        editLayoutProfile(layoutProfileIndex);
        break;
      case ContextMenuAction.DELETE:
        deleteLayoutProfile(layoutProfileIndex);
        break;
    }
  }, [deleteLayoutProfile, editLayoutProfile, layoutProfileIndex, saveLayoutProfile]);

  const contextMenuItems: MenuProps["items"] = useMemo(() => Object.values(ContextMenuAction).map((action) => ({
    key: action,
    label: t(action),
    onClick: () => onContextMenuAction(action),
  })), [onContextMenuAction, t]);

  return (
    <Tooltip title={name} placement="bottomLeft">
      <Dropdown menu={{ items: contextMenuItems }} trigger={["contextMenu"]}>
        <Button
          icon={<CharLayoutProfileIcon char={`${layoutProfileIndex + 1}`}/>}
          type="text"
          onClick={onApplyLayoutProfile}
        />
      </Dropdown>
    </Tooltip>
  );
};
