import { FilterChangedEvent, ITextFloatingFilterParams, TextFilterModel, TextFloatingFilter } from "ag-grid-community";

export class ExTextFloatingFilterComponent extends TextFloatingFilter {
  init(params: ITextFloatingFilterParams) {
    super.init(params);

    const filterInputField = this.getGui().querySelector("input");

    let timeoutId: ReturnType<typeof setTimeout>;
    const onInputBoxChanged = () => {
      if (filterInputField) {
        // stop existing timeout
        if (timeoutId)
          clearTimeout(timeoutId);

        const filterValue = filterInputField.value;

        timeoutId = setTimeout(() => {
          const columnFilterModel = params.api.setColumnFilterModel<TextFilterModel>(params.column, {
            type: "equals",
            filterType: "text",
            filter: filterValue,
          });
          columnFilterModel.finally(() => {
            params.api.onFilterChanged();
            filterInputField.focus();
          });
        }, 500);
      }
    };

    // when input field found, add eventListener for changes
    if (filterInputField)
      filterInputField.addEventListener("input", onInputBoxChanged);
  }

  onParentModelChanged(parentModel: TextFilterModel, event: FilterChangedEvent) {
    super.onParentModelChanged(parentModel, event);
    this.setEditable(true);
  }
}
