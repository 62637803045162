import { UserAddOutlined } from "@ant-design/icons";
import { Project } from "@org-avp/avp-avengers-project-service-api";
import { AvengersRole, CrudOp, roleAssignmentOverviewAuthScope, useAuthHelper } from "@org-avp/avp-avengers-ui-framework-oidc";
import { stringifyProjectName, useDebounced } from "@org-avp/avp-avengers-ui-framework-util";
import { Button, Input, Select, SelectProps, Tooltip } from "antd";
import { ChangeEventHandler, FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./RoleAssignmentTabToolbar.module.less";

export interface RoleAssignmentTabToolbarProps {
  onAddUserButtonClick: () => void;

  onSearchChange: (search: string) => void;

  projects: Project[];
  onProjectFilterChange: (projectId: string) => void;

  roles: AvengersRole[];
  onRoleFilterChange: (roles: AvengersRole[]) => void;
}


export const RoleAssignmentTabToolbar: FC<RoleAssignmentTabToolbarProps> = (
  {
    onAddUserButtonClick,
    onSearchChange,
    projects,
    onProjectFilterChange,
    roles,
    onRoleFilterChange,
  },
) => {
  const { evaluate } = useAuthHelper(roleAssignmentOverviewAuthScope);
  useTranslation();

  const [searchValue, setSearchValue] = useState<string>("");
  const onSearchValueChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => setSearchValue(value),
    [],
  );

  const debouncedSearchValue = useDebounced(searchValue, 200);
  useEffect(
    () => onSearchChange(debouncedSearchValue),
    [debouncedSearchValue, onSearchChange],
  );

  const projectFilterOptions = useMemo(() => projects.map((project) => ({
    label: stringifyProjectName(project.name),
    value: project.projectId,
  })), [projects]);
  const searchProjectFilterOptions = useCallback((input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase()), []);

  const [roleFilter, setRoleFilter] = useState<AvengersRole[]>([]);
  const onRoleFilterChangeAction = useCallback((roles: AvengersRole[]) => {
    setRoleFilter(roles);
    onRoleFilterChange(roles);
  }, [onRoleFilterChange]);
  const roleFilterOptions: SelectProps["options"] = useMemo(() => roles.map((role) => ({
    label: <Trans ns="app" i18nKey={`roleManagementView.roles.${role}`}/>,
    value: role,
  })), [roles]);

  return (
    <div className={styles.toolbar}>
      <Button icon={<UserAddOutlined/>} onClick={onAddUserButtonClick} disabled={!evaluate(CrudOp.CREATE)}>
        <Trans ns="app" i18nKey="roleManagementView.dialog.addUser.buttonLabel"/>
      </Button>
      <Input.Search allowClear value={searchValue} onChange={onSearchValueChange} className={styles.searchBar}/>
      <Select
        allowClear
        showSearch
        placeholder={<Trans ns="app" i18nKey="roleManagementView.inputPlaceholders.project"/>}
        onChange={onProjectFilterChange}
        options={projectFilterOptions}
        filterOption={searchProjectFilterOptions}
        className={styles.filterSelect}
      />
      <Select
        allowClear
        mode="multiple"
        placeholder={<Trans ns="app" i18nKey="roleManagementView.inputPlaceholders.role"/>}
        onChange={onRoleFilterChangeAction}
        value={roleFilter}
        options={roleFilterOptions}
        maxTagCount="responsive"
        maxTagPlaceholder={(values) => {
          const title = values.map(({ value }, index) => (
            <Fragment key={value}>
              <Trans ns="app" i18nKey={`roleManagementView.roles.${value}`}/>
              {index < values.length - 1 && ", "}
            </Fragment>
          ));

          const text = `+${values?.length}`;
          return <Tooltip title={title}>{text}</Tooltip>;
        }}
        className={styles.filterSelect}
      />
    </div>
  );
};
