import { LogoutOutlined } from "@ant-design/icons";
import { selectOidcUserData } from "@org-avp/avp-avengers-ui-framework-oidc";
import { Button, Tooltip } from "antd";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import styles from "./TitleBar.module.less";
import { useTitle } from "./use-title";

export interface TitleBarProps {
  showUserInfo: boolean;
  showLogout: boolean;
}

export const TitleBar: FC<TitleBarProps> = (props) => {
  const { t } = useTranslation("common");
  const { userInfo } = useAppSelector(selectOidcUserData);
  const title = useTitle();

  return (
    <div className={styles.title}>
      <div className={styles.logoPlaceholder}/>
      <div>{title}</div>
      {props.showUserInfo && <div className={styles.push}>{userInfo.email ?? <Trans ns="app" i18nKey="titleBar.emailNotAvailable"/>}</div>}
      <div className={styles.iconContainer}>
        {props.showLogout && (
          <Link to="/logout" className={styles.icon}>
            <Tooltip title={t("logout")} placement="bottomRight">
              <Button icon={<LogoutOutlined/>} type="text"/>
            </Tooltip>
          </Link>
        )}
      </div>
    </div>
  );
};
