import { Brand, ProjectName } from "@org-avp/avp-avengers-project-service-api";
import { selectAgGridTheme, updateOverlay } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { AvengersRole, CrudOp, roleAssignmentAuthScope, useAuthHelper } from "@org-avp/avp-avengers-ui-framework-oidc";
import { stringifyProjectName } from "@org-avp/avp-avengers-ui-framework-util";
import { User } from "@org-avp/avp-avengers-user-service-api";
import { GridOptions, ICellRendererParams, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../app/hooks";
import { useRoleAssignmentColumnDefs } from "./columns/use-roleAssignment-columnDefs";
import { AddUserDialog } from "./dialogs/add-user-dialog/AddUserDialog";
import styles from "./RoleAssignmentTab.module.less";
import { RoleAssignmentTabToolbar, RoleAssignmentTabToolbarProps } from "./toolbar/RoleAssignmentTabToolbar";
import { useKvsMainClientHelper } from "./use-kvs-main-client-helper";
import { ProjectUserRoleRow, useRoleAssignmentHelper } from "./use-role-assignment-helper";
import { UserSelect } from "./user-select/UserSelect";

export interface RoleAssignmentTabProps {
  brand: Brand;
  users?: User[];
}

const roles: AvengersRole[] = [
  AvengersRole.PROJECT_MANAGER,
  AvengersRole.OPERATOR,
  AvengersRole.LIBRARY_OPERATOR,
  AvengersRole.OPERATOR_EDITORIAL,
  AvengersRole.READER,
];

const fallbackProjectName: ProjectName = { projectCode: "", bodyTypes: [], productIds: [] };

export const RoleAssignmentTab: FC<RoleAssignmentTabProps> = ({ brand, users }) => {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeOverlay = useCallback(() => appDispatch(updateOverlay(null)), [appDispatch]);

  const [editedRows, setEditedRows] = useState<Set<ProjectUserRoleRow>>(new Set());
  const onSaveSuccess = useCallback(() => setEditedRows(new Set()), []);

  const {
    projects,
    kiraIds,
    projectUserRoles,
    addUserDialogOpen,
    onAddUserButtonClick,
    onAddUserDialogClose,
    onSave,
  } = useRoleAssignmentHelper(brand, onSaveSuccess);

  const { evaluate } = useAuthHelper(roleAssignmentAuthScope);
  const isUpdateForBrandEnabled = useMemo(() => {
    return evaluate(CrudOp.UPDATE, brand);
  }, [brand, evaluate]);

  const gridApiRef = useRef<AgGridReact<ProjectUserRoleRow>>(null);

  const [searchString, setSearchString] = useState<string>();
  const onSearchChange = useCallback(
    (search: string) => setSearchString(search),
    [],
  );

  const [projectIdFilter, setProjectIdFilter] = useState<string>();
  const onProjectFilterChange = useCallback(
    (projectId: string) => setProjectIdFilter(projectId),
    [],
  );
  useEffect(
    () => {
      gridApiRef.current?.api?.collapseAll();
      gridApiRef.current?.api?.forEachNode((node) => {
        if (node.key === projectIdFilter) {
          node.setExpanded(true);
        }
      });
    },
    [projectIdFilter],
  );

  const [rolesFilter, setRolesFilter] = useState<AvengersRole[]>([]);
  const onRoleFilterChange = useCallback(
    (roles: AvengersRole[]) => setRolesFilter(roles),
    [],
  );

  const isExternalFilterPresent = useCallback(
    () => !!projectIdFilter || rolesFilter.length > 0,
    [projectIdFilter, rolesFilter.length],
  );
  const doesExternalFilterPass = useCallback(
    (node: IRowNode<ProjectUserRoleRow>) => {
      if (node.data == null) return false;
      const projectPass = !projectIdFilter || projectIdFilter === node.data.project.projectId;
      const rolesPass = rolesFilter.length === 0 || node.data.roles.some((it) => rolesFilter.includes(it as AvengersRole));
      return projectPass && rolesPass;
    },
    [projectIdFilter, rolesFilter],
  );

  const toolbarParams = useMemo((): RoleAssignmentTabToolbarProps => ({
    onAddUserButtonClick,
    onSearchChange,
    projects,
    onProjectFilterChange,
    roles,
    onRoleFilterChange,
  }), [onAddUserButtonClick, onProjectFilterChange, onRoleFilterChange, onSearchChange, projects]);

  const addEditedRow = useCallback((row: ProjectUserRoleRow) => {
    setEditedRows((prev) => new Set([...prev, row]));
  }, []);

  const columnDefs = useRoleAssignmentColumnDefs(roles, addEditedRow, brand);

  const projectGroupRowRenderer = useCallback(({ value }: ICellRendererParams<ProjectUserRoleRow>) => {
    const project = projects.find((p) => p.projectId === value);
    return stringifyProjectName(project?.name ?? fallbackProjectName);
  }, [projects]);

  const groupRowRendererParams: GridOptions["groupRowRendererParams"] = useMemo(() => ({
    innerRenderer: projectGroupRowRenderer,
    suppressCount: true,
  }), [projectGroupRowRenderer]);

  const gridTheme = useSelector(selectAgGridTheme);

  const onSaveClick = useCallback(() => onSave(editedRows), [editedRows, onSave]);
  const saveButtonDisabled = useMemo(() => editedRows.size === 0 || !isUpdateForBrandEnabled, [editedRows.size, isUpdateForBrandEnabled]);

  const kvsMainClientHelper = useKvsMainClientHelper(brand);

  return (
    <>
      <UserSelect
        label={t("app:roleManagementView.kvsMainClient.label")}
        users={kvsMainClientHelper.isLoading ? undefined : users}
        selectedUserId={kvsMainClientHelper.kvsMainClientUserId}
        onUserSelect={isUpdateForBrandEnabled ? kvsMainClientHelper.onUserSelect : undefined}
      />
      <RoleAssignmentTabToolbar {...toolbarParams}/>
      <div className={gridTheme} style={{ height: "100%" }}>
        <AgGridReact<ProjectUserRoleRow>
          ref={gridApiRef}
          rowData={projectUserRoles}
          columnDefs={columnDefs}
          groupDisplayType="groupRows"
          groupRowRendererParams={groupRowRendererParams}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          quickFilterText={searchString}
        />
      </div>
      <div className={styles.buttonFooter}>
        <Button onClick={closeOverlay}><Trans ns="common" i18nKey="cancel"/></Button>
        <Button type="primary" disabled={saveButtonDisabled} onClick={onSaveClick}><Trans ns="common" i18nKey="save"/></Button>
      </div>
      <AddUserDialog open={addUserDialogOpen} existingUsersKiraIds={kiraIds} onClose={onAddUserDialogClose}/>
    </>
  );
};
