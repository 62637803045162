import { selectFullSizeViews, selectOverlay } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./OverlayView.module.less";

export const OverlayView: FC = () => {
  const fullSizeViews = useMemo(selectFullSizeViews, []);
  const displayName = useSelector(selectOverlay);
  const activeFullSizeView = useMemo(
    () => fullSizeViews[displayName ?? ""],
    [displayName, fullSizeViews],
  );

  return (
    <div className={styles.overlay}>
      {activeFullSizeView?.el}
    </div>
  );
};
