import { Brand } from "@org-avp/avp-avengers-project-service-api";
import { AvengersRole } from "@org-avp/avp-avengers-ui-framework-oidc";
import { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { ProjectUserRoleRow } from "../use-role-assignment-helper";
import { useColDefDepartment } from "./use-colDef-department";
import { useColDefFirstName } from "./use-colDef-firstName";
import { useColDefKiraId } from "./use-colDef-kiraId";
import { useColDefLastName } from "./use-colDef-lastName";
import { useColDefProject } from "./use-colDef-project";
import { useColDefRoles } from "./use-colDef-roles";

export type RoleColDef = ColDef<ProjectUserRoleRow>;

export const useRoleAssignmentColumnDefs = (
  displayedRoles: AvengersRole[],
  addEditedRow: (r: ProjectUserRoleRow) => void,
  brand: Brand,
): RoleColDef[] => {
  const project = useColDefProject();
  const kiraId = useColDefKiraId();
  const lastName = useColDefLastName();
  const firstName = useColDefFirstName();
  const department = useColDefDepartment();
  const roleColumns = useColDefRoles(displayedRoles, addEditedRow, brand);

  return useMemo(() => [
    project,
    kiraId,
    lastName,
    firstName,
    department,
    ...roleColumns,
  ], [project, kiraId, lastName, firstName, department, roleColumns]);
};
