import { AvengersTheme, selectTheme, setTheme } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { Select } from "antd";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";

export const ThemeSelect = () => {
  const appDispatch = useAppDispatch();
  const theme = useAppSelector(selectTheme);
  const changeTheme = (theme: AvengersTheme) => {
    appDispatch(setTheme(theme));
  };

  return (
    <>
      <Select<AvengersTheme> value={theme} onChange={(newTheme) => changeTheme(newTheme)}>
        <Select.Option value={AvengersTheme.LIGHT}><Trans ns="app" i18nKey="settingsView.themeSelector.LIGHT"/></Select.Option>
        <Select.Option value={AvengersTheme.DARK}><Trans ns="app" i18nKey="settingsView.themeSelector.DARK"/></Select.Option>
      </Select>
    </>
  );
};
