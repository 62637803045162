import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import asc from "../../../../../public/downloads/foss/as-connector.txt";
import ec from "../../../../../public/downloads/foss/electron-client.txt";
import wc from "../../../../../public/downloads/foss/web-client.txt";
import { environment } from "../../../../environment";
import styles from "./ImprintView.module.less";

export const ImprintView: FC = () => {
  useTranslation();

  return (
    <div className={styles.container}>

      <div className={styles.label}><Trans ns="app" i18nKey="imprintView.imprint.label"/></div>

      <div className={styles.paragraph}>
        <a target="_blank" rel="noreferrer" href={environment.NX_IMPRINT_DATAPROTECTIONSTATEMENT_URL}>
          <Trans ns="app" i18nKey="imprintView.imprint.dataProtection">
            <span className={styles.boldText}/>
          </Trans>
        </a>
      </div>

      <div className={styles.paragraph}>
        <a href={environment.NX_IMPRINT_VWAG_URL} target="_blank" rel="noreferrer">
          <Trans ns="app" i18nKey="imprintView.imprint.vwAG">
            <span className={styles.boldText}/>
          </Trans>
        </a>
      </div>

      <div className={styles.label}><Trans ns="app" i18nKey="imprintView.foss.label"/></div>

      <ul>
        <li>
          <a className={styles.boldText} target="_blank" rel="noreferrer" href={asc}>
            <Trans ns="app" i18nKey="imprintView.foss.asConnector"/>
          </a>
        </li>
        <li>
          <a className={styles.boldText} target="_blank" rel="noreferrer" href={ec}>
            <Trans ns="app" i18nKey="imprintView.foss.electronClient"/>
          </a>
        </li>
        <li>
          <a className={styles.boldText} target="_blank" rel="noreferrer" href={wc}>
            <Trans ns="app" i18nKey="imprintView.foss.webClient"/>
          </a>
        </li>
      </ul>
    </div>
  );
};
