import { CloseOutlined, ExportOutlined } from "@ant-design/icons";
import { RegisteredView } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { selectProjectState } from "@org-avp/avp-avengers-ui-framework-services/project";
import { Button, Tooltip } from "antd";
import { FC, useCallback } from "react";
import { Trans } from "react-i18next";
import { useAppSelector } from "../../../../../app/hooks";
import { removeViewFromLayout, undockViewIntoPopup } from "../../../utils/layoutHelpers";
import styles from "./TabActions.module.less";

export interface TabActionsProps {
  view: RegisteredView;
}

export const TabActions: FC<TabActionsProps> = (props) => {
  const { currentProject, currentSubproject } = useAppSelector(selectProjectState);
  if (!currentProject || !currentSubproject) {
    throw new Error("not in a subproject");
  }

  const onClose = useCallback(() => removeViewFromLayout(props.view), [props.view]);
  const onUndock = useCallback(
    () => undockViewIntoPopup(props.view, currentProject, currentSubproject),
    [props.view, currentProject, currentSubproject],
  );

  return (
    <div className={styles.tabActions}>
      <Tooltip title={<Trans ns="common" i18nKey="detach"/>} placement="bottomRight"><Button type="text" icon={<ExportOutlined/>} onClick={onUndock}/></Tooltip>
      <Tooltip title={<Trans ns="common" i18nKey="close"/>} placement="bottomRight"><Button type="text" icon={<CloseOutlined/>} onClick={onClose}/></Tooltip>
    </div>
  );
};
