import { dndHandleColumnId } from "@org-avp/avp-avengers-ui-framework-components";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export type GridFilterModel = { [p: string]: unknown };

export const geometryViewOptionsSliceName = "geometry";

export enum GeometryViewColumn {
  ALTERNATIVE = "alternative",
  BRANDING = "branding",
  COMMENT = "comment",
  COPYRIGHT_RESTRICTED = "copyrightRestricted",
  CREATED_AT = "createdAt",
  GEOMETRY_KEY = "geometryKey",
  GLOBAL_STATE = "globalState",
  LAST_CHANGED_AT = "lastChangedAt",
  LOCK_STATE = "lockState",
  NAME = "name",
  PDA_TYPE = "pdaType",
  PENDING_STATE = "pendingState",
  PREPARED_STATE = "preparedState",
  REPRESENTATION_COUNT = "representationCount",
  VERSION = "version",
}

export const geometryViewColumnIds = [
  dndHandleColumnId,
  GeometryViewColumn.PREPARED_STATE,
  GeometryViewColumn.GLOBAL_STATE,
  GeometryViewColumn.LOCK_STATE,
  GeometryViewColumn.PENDING_STATE,
  GeometryViewColumn.COPYRIGHT_RESTRICTED,
  GeometryViewColumn.GEOMETRY_KEY,
  GeometryViewColumn.NAME,
  GeometryViewColumn.PDA_TYPE,
  GeometryViewColumn.ALTERNATIVE,
  GeometryViewColumn.VERSION,
  GeometryViewColumn.BRANDING,
  GeometryViewColumn.REPRESENTATION_COUNT,
  GeometryViewColumn.COMMENT,
  GeometryViewColumn.CREATED_AT,
  GeometryViewColumn.LAST_CHANGED_AT,
];

export type GeometryViewOptionsState = {
  columns: string[];
  filterModel: GridFilterModel;
};

const persistedGeometryOptions = localStorage.getItem(geometryViewOptionsSliceName);
const persistedState = persistedGeometryOptions ? JSON.parse(persistedGeometryOptions) as GeometryViewOptionsState : null;
const fallback: GeometryViewOptionsState = {
  columns: geometryViewColumnIds,
  filterModel: {},
};
const initialState: GeometryViewOptionsState = persistedState ?? fallback;
// if columns does not exist, set default fallback value
if (initialState.columns === undefined || initialState.columns.length === 0) {
  initialState.columns = fallback.columns;
}


const geometryViewOptionsSlice = createSlice({
  name: geometryViewOptionsSliceName,
  initialState,
  reducers: {
    setGeometryViewOptions(state: GeometryViewOptionsState, { payload }: PayloadAction<GeometryViewOptionsState>) {
      return payload;
    },
    setGeometryViewColumns(state: GeometryViewOptionsState, { payload }: PayloadAction<string[]>) {
      const next: GeometryViewOptionsState = { ...state, columns: payload };
      persistState(next);
      return next;
    },
    setGeometryViewFilterModel(state: GeometryViewOptionsState, { payload }: PayloadAction<GridFilterModel>) {
      const next: GeometryViewOptionsState = { ...state, filterModel: payload };
      persistState(next);
      return next;
    },
  },
});

export type GeometryOptionsRootState = { [geometryViewOptionsSliceName]: GeometryViewOptionsState };
export const selectGeometryViewOptions = (state: GeometryOptionsRootState) => state[geometryViewOptionsSliceName];
export const setGeometryViewColumns = (columns: string[]) => (dispatch: Dispatch) => {
  dispatch(geometryViewOptionsSlice.actions.setGeometryViewColumns(columns));
};
export const setGeometryViewFilterModel = (filterModel: GridFilterModel) => (dispatch: Dispatch) => {
  dispatch(geometryViewOptionsSlice.actions.setGeometryViewFilterModel(filterModel));
};

function persistState(state: GeometryViewOptionsState) {
  localStorage.setItem(geometryViewOptionsSliceName, JSON.stringify(state));
}

export const geometryOptionsReducer = geometryViewOptionsSlice.reducer;
