import { DockMode, LayoutBase } from "rc-dock";

export type AnyNode = LayoutNode | PanelNode | TabNode;
export type LayoutNode = { id?: string; mode: DockMode; children: AnyNode[] };
export type PanelNode = { id?: string; tabs: TabNode[] };
export type TabNode = { id?: string };

export function traverseLayout(layout: LayoutBase, action: (node: AnyNode, parent?: AnyNode) => void) {
  traverseNode(layout.dockbox, action);
  traverseNode(layout.floatbox, action);
  traverseNode(layout.windowbox, action);
  traverseNode(layout.maxbox, action);
}

function traverseNode(node: AnyNode | null | undefined, action: (node: AnyNode, parent?: AnyNode) => void, parent?: AnyNode) {
  if (node === null || node === undefined) return;
  (node as PanelNode)?.tabs?.forEach((it) => traverseNode(it, action, node));
  (node as LayoutNode)?.children?.forEach((it) => traverseNode(it, action, node));
  action(node, parent);
}
