import { ColumnParams, CommonColumnParamsState } from "@org-avp/avp-avengers-ui-framework-components";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export const tmmEditorViewOptionsSliceName = "tmmEditor";

export enum TmmEditorColumn {
  ADI = "ADI",
  COMMENT = "COMMENT",
  INDEX = "INDEX",
  LABEL = "LABEL",
  MATERIAL = "MATERIAL",
  RULE = "RULE",
  TRIGGER = "TRIGGER",
  TYPE = "TYPE",
  USE = "USE",
}

export const tmmEditorColumnIds = [
  TmmEditorColumn.ADI,
  TmmEditorColumn.USE,
  TmmEditorColumn.RULE,
  TmmEditorColumn.TRIGGER,
  TmmEditorColumn.MATERIAL,
  TmmEditorColumn.LABEL,
  TmmEditorColumn.INDEX,
  TmmEditorColumn.TYPE,
  TmmEditorColumn.COMMENT,
];

export type TmmEditorViewOptionsState = CommonColumnParamsState;

const persistedTmmEditorViewOptions = localStorage.getItem(tmmEditorViewOptionsSliceName);
const persistedState = persistedTmmEditorViewOptions ? JSON.parse(persistedTmmEditorViewOptions) : null;

const fallback: TmmEditorViewOptionsState = {
  columns: tmmEditorColumnIds,
  params: Object.fromEntries(tmmEditorColumnIds.map((columnId) => [columnId, { columnId: columnId }])),
};

const initialState: TmmEditorViewOptionsState = persistedState ?? fallback;

// if columns does not exist, set default fallback value
if (initialState.columns === undefined || initialState.columns.length === 0) {
  initialState.columns = fallback.columns;
}

if (initialState.params === undefined)
  initialState.params = fallback.params;

const tmmEditorViewOptionsSlice = createSlice({
  name: tmmEditorViewOptionsSliceName,
  initialState,
  reducers: {
    setTmmEditorViewColumns(state: TmmEditorViewOptionsState, action: PayloadAction<string[]>) {
      const next: TmmEditorViewOptionsState = { ...state, columns: action.payload };
      persistState(next);
      return next;
    },
    setLayoutProfileString(state: TmmEditorViewOptionsState, action: PayloadAction<string>) {
      const newState = JSON.parse(action.payload);
      persistState(newState);
      return newState;
    },
    updateColumnWidth(state: TmmEditorViewOptionsState, action: PayloadAction<ColumnParams>) {
      if (!state.params) // init with new function
        state.params = {};
      state.params[action.payload.columnId] = action.payload;

      persistState(state);
      return state;
    },
  },
});

export type TmmEditorViewOptionsRootState = { [tmmEditorViewOptionsSliceName]: TmmEditorViewOptionsState };
export const selectTmmEditorViewOptions = (state: TmmEditorViewOptionsRootState) => state[tmmEditorViewOptionsSliceName];
export const selectTmmEditorLayoutProfileString = (state: TmmEditorViewOptionsRootState) => JSON.stringify(state[tmmEditorViewOptionsSliceName]);

export const setTmmEditorLayoutProfileString = (layoutProfileString: string) => (dispatch: Dispatch) => {
  dispatch(tmmEditorViewOptionsSlice.actions.setLayoutProfileString(layoutProfileString));
};
export const setTmmEditorViewColumns = (columns: string[]) => (dispatch: Dispatch) => {
  dispatch(tmmEditorViewOptionsSlice.actions.setTmmEditorViewColumns(columns));
};
export const updateColumnWidth = (columnId: string, columnWidth?: number) => (dispatch: Dispatch) => {
  dispatch(tmmEditorViewOptionsSlice.actions.updateColumnWidth({ columnId, width: columnWidth }));
};

function persistState(state: TmmEditorViewOptionsState) {
  localStorage.setItem(tmmEditorViewOptionsSliceName, JSON.stringify(state));
}

export const tmmEditorViewOptionsReducer = tmmEditorViewOptionsSlice.reducer;
