import { ValueGetterParams } from "ag-grid-community";
import { useCallback, useMemo } from "react";
import { ProjectUserRoleRow } from "../use-role-assignment-helper";
import { RoleColDef } from "./use-roleAssignment-columnDefs";

export const useColDefProject = (): RoleColDef => {
  const valueGetter = useCallback(({ data }: ValueGetterParams<ProjectUserRoleRow>) => data?.project.projectId, []);

  return useMemo(() => ({
    suppressColumnsToolPanel: true,
    colId: "project",
    valueGetter,
    rowGroup: true,
    hide: true,
  }), [valueGetter]);
};
