import { userApi } from "@org-avp/avp-avengers-ui-framework-services/user";
import { UserSettingsUpdate } from "@org-avp/avp-avengers-user-service-api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutProfileDialogProps } from "./LayoutProfileDialog";
import { LayoutProfile } from "./model";
import { useLayoutProfileHelper } from "./use-layout-profile-helper";

export interface UseLayoutProfilesHelper {
  layoutProfiles: LayoutProfile[];

  applyLayoutProfile: (layoutProfileIndex: number) => void;
  deleteLayoutProfile: (layoutProfileIndex: number) => void;
  updateLayoutProfileData: (layoutProfileIndex: number) => void;

  isCreateLayoutProfileDisabled: boolean;

  openLayoutProfileDialog: (layoutProfileIndex?: number) => void;
  layoutProfileDialogProps?: LayoutProfileDialogProps;
}

export const LAYOUT_PROFILES_SETTINGS_KEY = "layoutProfiles";

export const useLayoutProfilesHelper = (): UseLayoutProfilesHelper => {
  const { t } = useTranslation();

  const layoutProfileHelper = useLayoutProfileHelper();

  // userSettings: layoutProfiles
  const { data: userSettings } = userApi.useGetUserSettingsQuery({ settings: [LAYOUT_PROFILES_SETTINGS_KEY] });
  const [layoutProfiles, setLayoutProfiles] = useState<LayoutProfile[]>([]);
  useEffect(() => {
    const layoutProfilesJson = userSettings?.settings[0]?.value ?? undefined as string | undefined;
    setLayoutProfiles(parseLayoutProfiles(layoutProfilesJson));
  }, [userSettings]);

  // trigger: save userSettings to backend
  const [updateUserSettings] = userApi.useUpdateUserSettingsMutation();

  // save layout profiles to DB as user settings
  const saveLayoutProfiles = useCallback((futureLayoutProfiles: LayoutProfile[]) => {
    const userSettingsUpdate: UserSettingsUpdate = {
      settings: [
        { key: LAYOUT_PROFILES_SETTINGS_KEY, value: JSON.stringify(futureLayoutProfiles) },
      ],
    };
    updateUserSettings(userSettingsUpdate)
      .unwrap()
      .then(() => setLayoutProfiles(futureLayoutProfiles));
  }, [updateUserSettings]);

  // layoutProfile dialog data
  const [layoutProfileDialogProps, setLayoutProfileDialogProps] = useState<LayoutProfileDialogProps>();

  // apply a layout profile
  const applyLayoutProfile = useCallback((layoutProfileIndex: number) => {
    const layoutProfile: LayoutProfile | undefined = layoutProfiles[layoutProfileIndex];
    if (layoutProfile) {
      layoutProfileHelper.applyLayoutProfile(layoutProfile);
    }
  }, [layoutProfileHelper, layoutProfiles]);

  // save layout profile (create or edit)
  const onLayoutProfileDialogSave = useCallback((layoutProfileDescription: string, layoutProfileIndex?: number) => {
    if (layoutProfileIndex !== undefined) {
      const currentLayoutProfile = layoutProfiles[layoutProfileIndex];
      const layoutProfile: LayoutProfile = { ...currentLayoutProfile, name: layoutProfileDescription };
      const futureProfiles = [...layoutProfiles];
      futureProfiles.splice(layoutProfileIndex, 1, layoutProfile);
      saveLayoutProfiles(futureProfiles);
    } else {
      const layoutProfile: LayoutProfile = {
        name: layoutProfileDescription,
        data: layoutProfileHelper.getCurrentLayoutProfileData(),
      };
      saveLayoutProfiles([...layoutProfiles, layoutProfile]);
    }
    setLayoutProfileDialogProps(undefined);
  }, [layoutProfileHelper, layoutProfiles, saveLayoutProfiles]);

  // close dialog on cancel
  const onLayoutProfileDialogCancel = useCallback(() => {
    setLayoutProfileDialogProps(undefined);
  }, []);

  // persist layout data to layout profile
  const updateLayoutProfileData = useCallback((layoutProfileIndex: number) => {
    const currentLayoutProfile = layoutProfiles[layoutProfileIndex];
    const layoutProfile: LayoutProfile = {
      name: currentLayoutProfile.name,
      data: layoutProfileHelper.getCurrentLayoutProfileData(),
    };
    const futureLayoutProfiles = [...layoutProfiles];
    futureLayoutProfiles.splice(layoutProfileIndex, 1, layoutProfile);
    saveLayoutProfiles(futureLayoutProfiles);
  }, [layoutProfileHelper, layoutProfiles, saveLayoutProfiles]);

  // delete layout profile
  const deleteLayoutProfile = useCallback((layoutProfileIndex: number) => {
    const futureProfiles = [...layoutProfiles];
    futureProfiles.splice(layoutProfileIndex, 1);
    saveLayoutProfiles(futureProfiles);
  }, [layoutProfiles, saveLayoutProfiles]);

  // open layout profile dialog (create a new one or edit an existing one)
  const openLayoutProfileDialog = useCallback((layoutProfileIndex?: number) => {
    if (layoutProfileIndex !== undefined) {
      const layoutProfile = layoutProfiles[layoutProfileIndex];
      setLayoutProfileDialogProps({
        dialogTitle: t("app:operatorView.layoutProfiles.dialog.title.edit"),
        layoutProfileIndex,
        currentDescription: layoutProfile.name,
        onSave: (description: string) => onLayoutProfileDialogSave(description, layoutProfileIndex),
        onCancel: onLayoutProfileDialogCancel,
      });
    } else {
      setLayoutProfileDialogProps({
        dialogTitle: t("app:operatorView.layoutProfiles.dialog.title.create"),
        layoutProfileIndex: layoutProfiles.length,
        onSave: (description: string) => onLayoutProfileDialogSave(description),
        onCancel: onLayoutProfileDialogCancel,
      });
    }
  }, [layoutProfiles, onLayoutProfileDialogCancel, onLayoutProfileDialogSave, t]);

  return useMemo(() => ({
    applyLayoutProfile,
    deleteLayoutProfile,
    isCreateLayoutProfileDisabled: userSettings === undefined || layoutProfiles.length >= 5,
    layoutProfileDialogProps,
    layoutProfiles,
    openLayoutProfileDialog,
    updateLayoutProfileData,
  }), [
    applyLayoutProfile,
    deleteLayoutProfile,
    openLayoutProfileDialog,
    layoutProfileDialogProps,
    layoutProfiles,
    updateLayoutProfileData,
    userSettings,
  ]);
};

// ================================================================================
//  helper functions
// ================================================================================

const parseLayoutProfiles = (layoutProfilesJson?: string): LayoutProfile[] => {
  if (layoutProfilesJson) {
    try {
      const layoutProfiles = JSON.parse(layoutProfilesJson);
      if (isLayoutProfilesArray(layoutProfiles)) {
        return layoutProfiles;
      } else {
        console.warn("Failed to parse layout profiles: Unexpected format.");
      }
    } catch (e) {
      console.warn("Failed to parse layout profiles", e);
    }
  }
  return [];
};

const isLayoutProfilesArray = (maybeLayoutProfilesArray: unknown): maybeLayoutProfilesArray is LayoutProfile[] => {
  return Array.isArray(maybeLayoutProfilesArray) && maybeLayoutProfilesArray.every((it) => isLayoutProfile(it));
};

const isLayoutProfile = (maybeLayoutProfile: unknown): maybeLayoutProfile is LayoutProfile => {
  if (!maybeLayoutProfile || typeof maybeLayoutProfile !== "object") {
    return false;
  }

  type RequiredFieldNames = "name";
  type RequiredFields = Pick<LayoutProfile, RequiredFieldNames>;
  type OptionalFields = Exclude<keyof LayoutProfile, RequiredFieldNames>;
  type MaybeLayoutProfile = Partial<RequiredFields> & Record<OptionalFields, unknown>;

  const typedMaybeLayoutProfile = maybeLayoutProfile as MaybeLayoutProfile;

  return (
    // check required fields first
    typeof typedMaybeLayoutProfile.name === "string"

    // then check optional fields (are allowed to be absent)
    && (!typedMaybeLayoutProfile.data
      || (typeof typedMaybeLayoutProfile.data === "object" && Object.values(typedMaybeLayoutProfile.data).every((it) => typeof it === "string"))
    )
  );
};
