import { fallbackModuleDef, ModuleDef, registerModule, verifyModuleDef } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { appModule } from "../modules/app-module/appModule";

export const registerAllMicroFrontends = (): Promise<void> => {
  const asyncModules = [
    registerAppModule(),
    registerConfigurationModule(),
    registerGeometryModule(),
    registerProjectModule(),
    registerQaModule(),
    registerTemplateModule(),
  ];

  return Promise.all(asyncModules)
    .then((modules) => modules.forEach(registerModule))
    .then(() => console.log("All micro frontends registered"))
    .catch((e) => console.error("Error registering microfrontends:", e));
};

function registerAppModule(): Promise<ModuleDef> {
  return Promise.resolve(appModule);
}

async function registerConfigurationModule(): Promise<ModuleDef> {
  return import("@org-avp/avp-avengers-frontend-configuration-remote-entry")
    .then((importedModule) => verifyModuleDef(importedModule.moduleDef))
    .catch((err) => fallbackModuleDef("configuration", err));
}

async function registerGeometryModule(): Promise<ModuleDef> {
  return import("@org-avp/avp-avengers-frontend-geometry-remote-entry")
    .then((importedModule) => verifyModuleDef(importedModule.moduleDef))
    .catch((err) => fallbackModuleDef("geometry", err));
}

async function registerProjectModule(): Promise<ModuleDef> {
  return import("@org-avp/avp-avengers-frontend-project-remote-entry")
    .then((importedModule) => verifyModuleDef(importedModule.moduleDef))
    .catch((err) => fallbackModuleDef("project", err));
}

async function registerQaModule(): Promise<ModuleDef> {
  return import("@org-avp/avp-avengers-frontend-qa-remote-entry")
    .then((importedModule) => verifyModuleDef(importedModule.moduleDef))
    .catch((err) => fallbackModuleDef("qa", err));
}

async function registerTemplateModule(): Promise<ModuleDef> {
  return import("@org-avp/avp-avengers-frontend-template-remote-entry")
    .then((importedModule) => verifyModuleDef(importedModule.moduleDef))
    .catch((err) => fallbackModuleDef("template", err));
}
