import { selectProjectState } from "@org-avp/avp-avengers-ui-framework-services/project";
import { stringifyProjectName } from "@org-avp/avp-avengers-ui-framework-util";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { environment } from "../../../environment";

export const useTitle = () => {
  const { t } = useTranslation();

  const { currentProject, currentSubproject } = useAppSelector(selectProjectState);

  const envTitle = useMemo(() => environment.NX_ENV_NAME !== "PROD" ? `[${environment.NX_ENV_NAME}] ` : "", []);

  const mainTitle = useMemo(
    () => !currentProject || !currentSubproject
      ? t("app:titleBar.appName")
      : `${stringifyProjectName(currentProject.name)} - ${currentSubproject.name}`,
    [currentProject, currentSubproject, t],
  );

  const title = useMemo(() => `${envTitle}${mainTitle} (${t("app:titleBar.confidential")})`, [envTitle, mainTitle, t]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return title;
};
