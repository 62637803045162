import { RegisteredView, selectModularViews } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { clearAllPartlistFilters } from "@org-avp/avp-avengers-ui-framework-sam";
import { projectApi, selectProjectState, setCurrentProject, subprojectApi } from "@org-avp/avp-avengers-ui-framework-services/project";
import { DockLayout, LayoutBase, LayoutData, TabGroup } from "rc-dock";
import { FC, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectLayout, setLayout } from "../../slices/layoutSlice";
import { createTabData, DEFAULT_GROUP, DEFAULT_GROUP_KEY, setDockContext } from "../../utils/layoutHelpers";
import { AnyNode, traverseLayout } from "../../utils/layoutTraversal";
import { NotificationHandler } from "./notification-handler/NotificationHandler";
import { OperatorViewMenu } from "./operator-view-menu/OperatorViewMenu";
import dockStyles from "./OperatorDock.module.less";

export interface OperatorViewProps {
  projectId: string;
  subprojectId: string;
}

export const OperatorView: FC<OperatorViewProps> = ({ projectId, subprojectId }) => {
  const appDispatch = useAppDispatch();
  const { data: currentProject } = projectApi.useGetProjectByIdQuery(projectId);
  const { data: currentSubproject } = subprojectApi.useGetSubprojectByIdQuery([projectId, subprojectId]);
  useEffect(
    () => {
      appDispatch(setCurrentProject({ currentProject, currentSubproject }));

      return () => {
        appDispatch(clearAllPartlistFilters());
        appDispatch(setCurrentProject({}));
      };
    },
    [appDispatch, currentProject, currentSubproject],
  );

  const projectState = useAppSelector(selectProjectState);
  const isCurrentProjectUpdated = useMemo(
    () => currentProject && currentProject === projectState.currentProject && currentSubproject && currentSubproject === projectState.currentSubproject,
    [currentProject, currentSubproject, projectState.currentProject, projectState.currentSubproject],
  );

  return (
    <>
      {isCurrentProjectUpdated && <OperatorDock/>}
      <NotificationHandler projectState={projectState}/>
    </>
  );
};

const OperatorDock: FC = () => {
  const dispatch = useAppDispatch();
  const layout = useAppSelector(selectLayout);
  const hydratedLayout = useMemo(() => hydrateLayout(layout, selectModularViews()), [layout]);

  const dockLayoutRef = useRef<DockLayout>(null);
  useEffect(() => setDockContext(dockLayoutRef.current), [dockLayoutRef]);

  return (
    <div className={dockStyles.dockContainer}>
      <OperatorViewMenu/>
      <div className={dockStyles.dock}>
        <DockLayout
          defaultLayout={hydratedLayout}
          layout={hydratedLayout}
          groups={operatorDockGroups}
          ref={dockLayoutRef}
          onLayoutChange={(data) => dispatch(setLayout(data))}
        />
      </div>
    </div>
  );
};

const operatorDockGroups: Record<string, TabGroup> = {
  [DEFAULT_GROUP_KEY]: DEFAULT_GROUP,
};

function hydrateLayout(layout: LayoutBase, viewRecord: Record<string, RegisteredView>): LayoutData {
  const layoutData: LayoutData = JSON.parse(JSON.stringify(layout)); // deep copy
  traverseLayout(layoutData, (node, parent) => {
    if (!hydrateNode(node, viewRecord) && parent && "tabs" in parent && node.id) {
      // remove the node from the layout, when it could not be hydrated with a view
      parent.tabs = parent.tabs.filter((tab) => tab.id !== node.id);
    }
  });

  return layoutData;
}

// returns false, if a node could not be hydrated and needs to be removed
function hydrateNode(node: AnyNode, viewRecord: Record<string, RegisteredView>): boolean {
  const view = viewRecord[node.id ?? ""];
  if (view !== null && view !== undefined) {
    const mutableNode = node as Record<string, unknown>;
    Object.entries(createTabData(view)).forEach(([key, value]) => {
      mutableNode[key] = value;
    });

    return true;
  }

  // rc-dock layout node IDs are finite integers
  return isFinite(Number(node.id));
}
