import { AvengersTheme, selectTheme } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { ConfigProvider, theme, ThemeConfig } from "antd";
import { FC, PropsWithChildren, useMemo } from "react";
import { useAppSelector } from "../../app/hooks";

export const AvengersThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const avengersTheme = useAppSelector(selectTheme);
  const antdThemeConfig = useMemo(
    () => mapAvengersTheme(avengersTheme),
    [avengersTheme],
  );

  return (
    <ConfigProvider theme={antdThemeConfig}>
      {children}
    </ConfigProvider>
  );
};

const mapAvengersTheme = (avengersTheme: AvengersTheme): ThemeConfig => {
  switch (avengersTheme) {
    case AvengersTheme.DARK:
      return {
        algorithm: theme.darkAlgorithm,
      };
    case AvengersTheme.LIGHT:
      return {
        algorithm: theme.defaultAlgorithm,
      };
  }
};
