import { getElectronAPI } from "@org-avp/avp-avengers-ui-framework-electron-api";
import { LicenseManager } from "ag-grid-enterprise";
import { environment } from "./environment";

export { registerAllMicroFrontends } from "./app/modules";

// side effect: set AG Grid license key
LicenseManager.setLicenseKey(environment.NX_AGGRID_LICENSE_KEY);

// renders the React UI into the provided root element
export const renderAppShell = (rootEl: HTMLElement) => {
  import("./render").then(({ renderAppShell }) => renderAppShell(rootEl))
    .catch((err) => console.warn("Could not call renderAppShell():", err));
};

getElectronAPI()?.on.messageFromMain((event, text) => {
  console.log(`Received message from Electron Main Tread: ${text}`);
});
