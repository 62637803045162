import { ColumnParams, CommonColumnParamsState } from "@org-avp/avp-avengers-ui-framework-components";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export const geometryManagerOptionsSliceName = "geometryManager";

export enum AssemblyManagerColumn {
  CHANGE_DATE = "changeDate",
  CONDITION = "condition",
  CREATED_AT = "createdAt",
  DELETE_REPRESENTATION = "deleteRepresentation",
  DOCUMENT_NAME = "documentName",
  DOCUMENT_NUMBER = "documentNumber",
  TRIGGER = "trigger",
  VALIDITY = "validity",
}

export const assemblyMangerColumnIds = [
  AssemblyManagerColumn.DELETE_REPRESENTATION,
  AssemblyManagerColumn.DOCUMENT_NUMBER,
  AssemblyManagerColumn.DOCUMENT_NAME,
  AssemblyManagerColumn.CHANGE_DATE,
  AssemblyManagerColumn.CREATED_AT,
  AssemblyManagerColumn.VALIDITY,
  AssemblyManagerColumn.TRIGGER,
  AssemblyManagerColumn.CONDITION,
];

export type GeometryManagerOptionsState = {
  assemblyManager: AssemblyManagerOptions;
};

export type AssemblyManagerOptions = CommonColumnParamsState;

const persistedOptions = localStorage.getItem(geometryManagerOptionsSliceName);
const persistedState = persistedOptions ? JSON.parse(persistedOptions) as GeometryManagerOptionsState : null;

const fallback: GeometryManagerOptionsState = {
  assemblyManager: {
    columns: assemblyMangerColumnIds,
    params: Object.fromEntries(assemblyMangerColumnIds.map((columnId) => [columnId, { columnId: columnId }])),
  },
};

// TODO implement method who can do this automatically for all types of slice data
function buildInitialState(): GeometryManagerOptionsState {
  const initialState: GeometryManagerOptionsState = persistedState ?? fallback;

  // if no assembly manager data exists, add the fallback value
  if (initialState.assemblyManager === undefined)
    initialState.assemblyManager = fallback.assemblyManager;

  // if no assembly manager columns data exists, add the fallback value
  if (initialState.assemblyManager.columns === undefined || initialState.assemblyManager.columns.length === 0)
    initialState.assemblyManager.columns = fallback.assemblyManager.columns;

  if (initialState.assemblyManager.params === undefined)
    initialState.assemblyManager.params = fallback.assemblyManager.params;

  // persist state if items have been added to the persisted object
  if (initialState !== persistedState)
    persistState(initialState);

  return initialState;
}

const geometryManagerOptionsSlice = createSlice({
  name: geometryManagerOptionsSliceName,
  initialState: buildInitialState(),
  reducers: {
    setGeometryManagerOptions(_, { payload }: PayloadAction<GeometryManagerOptionsState>) {
      persistState(payload);
      return payload;
    },
    setLayoutProfileString(state: GeometryManagerOptionsState, action: PayloadAction<string>) {
      const newState = JSON.parse(action.payload);
      persistState(newState);
      return newState;
    },
    setAssemblyManagerColumns(state: GeometryManagerOptionsState, { payload: columns }: PayloadAction<string[]>) {
      const next: GeometryManagerOptionsState = { ...state, assemblyManager: { ...state.assemblyManager, columns } };
      persistState(next);
      return next;
    },
    updateColumnWidth(state: GeometryManagerOptionsState, action: PayloadAction<ColumnParams>) {
      if (!state.assemblyManager.params) // init with new function
        state.assemblyManager.params = {};
      state.assemblyManager.params[action.payload.columnId] = action.payload;

      persistState(state);
      return state;
    },
  },
});

export type GeometryManagerOptionsRootState = { [geometryManagerOptionsSliceName]: GeometryManagerOptionsState };
export const selectGeometryManagerOptions = (state: GeometryManagerOptionsRootState) => state[geometryManagerOptionsSliceName];
export const selectAssemblyManagerOptions = (state: GeometryManagerOptionsRootState) => state[geometryManagerOptionsSliceName].assemblyManager;

export const selectGeometryManagerLayoutProfileString = (state: GeometryManagerOptionsRootState) => JSON.stringify(state[geometryManagerOptionsSliceName]);

export const setGeometryManagerLayoutProfileString = (layoutProfileString: string) => (dispatch: Dispatch) => {
  dispatch(geometryManagerOptionsSlice.actions.setLayoutProfileString(layoutProfileString));
};

export const setAssemblyManagerColumns = (columns: string[]) => (dispatch: Dispatch) => {
  dispatch(geometryManagerOptionsSlice.actions.setAssemblyManagerColumns(columns));
};

export const updateAssemblyManagerColumnWidth = (columnId: string, columnWidth?: number) => (dispatch: Dispatch) => {
  dispatch(geometryManagerOptionsSlice.actions.updateColumnWidth({ columnId, width: columnWidth }));
};

function persistState(state: GeometryManagerOptionsState) {
  localStorage.setItem(geometryManagerOptionsSliceName, JSON.stringify(state));
}

export const geometryManagerOptionsReducer = geometryManagerOptionsSlice.reducer;
