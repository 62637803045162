import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExNumberFloatingFilterComponent } from "./ExNumberFloatingFilterComponent";
import { ExTextFloatingFilterComponent } from "./ExTextFloatingFilterComponent";

const tNS = "filters";
const tPrefix = "columnFilterType";

export const useColumnFilterTypes = <T>(): Record<string, ColDef<T>> => {
  const { t } = useTranslation(tNS);

  return useMemo(() => ({
    filterText: {
      filter: "agTextColumnFilter",
      floatingFilter: true,
      floatingFilterComponent: ExTextFloatingFilterComponent,
      filterParams: {
        maxNumConditions: 1,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
        filterOptions: [
          {
            displayKey: "match",
            displayName: t(`${tPrefix}.text.match`),
            test: () => true,
          },
        ],
      },
    },
    filterEnum: {
      filter: "agSetColumnFilter",
      floatingFilter: true,
      filterParams: {
        suppressSorting: true,
        suppressSelectAll: true,
        defaultToNothingSelected: true,
        buttons: ["apply", "clear", "cancel"],
        closeOnApply: true,
        values: [],
      },
    },
    filterBoolean: {
      filter: "agSetColumnFilter",
      floatingFilter: true,
      filterParams: {
        suppressSelectAll: true,
        defaultToNothingSelected: true,
        buttons: ["apply", "clear", "cancel"],
        values: ["true", "false"],
        suppressSorting: true,
        valueFormatter: (param: ValueFormatterParams) => {
          return param.value === "true" ? t(`${tPrefix}.boolean.checked`) : t(`${tPrefix}.boolean.unchecked`);
        },
        closeOnApply: true,
      },
    },
    filterNumber: {
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      floatingFilterComponent: ExNumberFloatingFilterComponent,
      filterParams: {
        closeOnApply: true,
        filterOptions: ["equals", "greaterThan", "greaterThanOrEqual", "inRange", "lessThan", "lessThanOrEqual"],
        buttons: ["apply", "clear", "reset", "cancel"],
        maxNumConditions: 1,
      },
    },
    filterDate: {
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: {
        closeOnApply: true,
        filterOptions: ["equals", "greaterThan", "inRange", "lessThan"],
        buttons: ["apply", "clear", "reset", "cancel"],
        maxNumConditions: 1,
      },
    },
    filterNone: {
      filter: null,
    },
  }), [t]);
};
