import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  MenuInclusion,
  RegisteredView,
  selectFullSizeViews,
  selectOverlay,
  updateOverlay,
  useProjectParam,
  ViewType,
} from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { AuthHelperCreator, useAuthHelperCreator } from "@org-avp/avp-avengers-ui-framework-oidc";
import { MenuProps } from "antd";
import { useCallback, useMemo } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { selectMenuOptions, toggleMenuCollapsed } from "../../../slices/menuOptionsSlice";

export type MenuItem = Required<MenuProps>["items"][number];

export interface MenuItemsHelper {
  topItems: MenuItem[];
  bottomItems: MenuItem[];
  collapsed: boolean;
  selectedKeys: string[];
}

export function useMenuItemsHelper(): MenuItemsHelper {
  const { t } = useTranslation();
  const fullSizeViews = useMemo(selectFullSizeViews, []);
  const currentOverlay = useSelector(selectOverlay);
  const location = useLocation();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  const selectedKeys: [string] | [] = useMemo(() => {
    const selectedKey = currentOverlay ?? Object.values(fullSizeViews).find((view) => `/${view.navigateTo}` === location.pathname)?.registeredViewKey;
    if (!selectedKey && (location.pathname === "/" || location.pathname === "")) {
      return ["app_home"];
    }
    return selectedKey ? [selectedKey] : [];
  }, [currentOverlay, fullSizeViews, location.pathname]);

  const showView = useCallback((viewKey: string) => () => {
    const currOverlay = currentOverlay;
    appDispatch(updateOverlay(null));
    if (viewKey !== selectedKeys[0] && viewKey !== currOverlay) {
      const fullSizeView = fullSizeViews[viewKey];
      if (fullSizeView.viewType === ViewType.OVERLAY) {
        appDispatch(updateOverlay(viewKey));
      } else {
        navigate(fullSizeView.navigateTo);
      }
    }
  }, [appDispatch, currentOverlay, fullSizeViews, navigate, selectedKeys]);

  const { collapsed } = useSelector(selectMenuOptions);
  const toggleCollapsed = useCallback(() => {
    appDispatch(toggleMenuCollapsed());
  }, [appDispatch]);

  const brand = useProjectParam("brand");
  const projectId = useProjectParam("projectId");
  const authHelperCreator = useAuthHelperCreator();

  const topItems: MenuItem[] = useMemo(
    () => Object.values(fullSizeViews)
      .filter((view) => view.menuDefinition.menuInclusion === MenuInclusion.TOP)
      .map((view) => mapRegisteredView(view, showView, t, authHelperCreator, brand, projectId))
      .reduce((acc, val) => acc.concat(val), []),
    [authHelperCreator, brand, fullSizeViews, projectId, showView, t],
  );

  const bottomItems: MenuItem[] = useMemo(
    () => [
      ...Object.values(fullSizeViews)
        .filter((view) => view.menuDefinition.menuInclusion === MenuInclusion.BOTTOM)
        .map((view) => mapRegisteredView(view, showView, t, authHelperCreator, brand, projectId))
        .reduce((acc, val) => acc.concat(val), []),
      { type: "divider" },
      {
        key: "toggle",
        icon: collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>,
        label: t(collapsed ? "app:menuBar.expand" : "app:menuBar.collapse"),
        onClick: toggleCollapsed,
      },
    ],
    [authHelperCreator, brand, collapsed, fullSizeViews, projectId, showView, t, toggleCollapsed],
  );

  return useMemo(() => ({ topItems, bottomItems, collapsed, selectedKeys }), [topItems, bottomItems, collapsed, selectedKeys]);
}

function mapRegisteredView(
  view: RegisteredView,
  showView: (viewKey: string) => () => void,
  t: UseTranslationResponse<"translation">["t"],
  authHelperCreator: AuthHelperCreator,
  brand: string | null | undefined,
  project: string | null | undefined,
): MenuItem[] {
  const items: MenuItem[] = [{
    key: view.registeredViewKey,
    icon: view.menuDefinition.menuIconDef,
    label: t(view.displayName),
    onClick: showView(view.registeredViewKey),
    disabled: !view.isAuthorized(authHelperCreator, { brand, project }),
  }];
  if (view.menuDefinition.hasMenuDividerBelow) {
    items.push({ type: "divider" });
  }
  return items;
}
