import { PartlistColumn } from "@org-avp/avp-avengers-partlist-service-api";
import { dndHandleColumnId } from "@org-avp/avp-avengers-ui-framework-components";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export type GridFilterModel = { [p: string]: unknown };

export const partlistViewOptionsSliceName = "partlist";
export const partlistColumnIds = [
  dndHandleColumnId,
  PartlistColumn.USAGE,
  PartlistColumn.GEOMETRY_BUILD_UP_STATE,
  PartlistColumn.BOM_FILTER_CLASS,
  PartlistColumn.BOM_PART_KEY,
  PartlistColumn.BOM_DESCRIPTION,
  PartlistColumn.NAME_SUFFIX,
  PartlistColumn.BOM_IS_COLOR_RELEVANT,
  PartlistColumn.BOM_INCLUSION_DATE,
  PartlistColumn.BOM_EXCLUSION_DATE,
  PartlistColumn.SUMMARY,
  PartlistColumn.BOM_ORIGINAL_RULE,
  PartlistColumn.MARKETING_RULES,
  PartlistColumn.MARKETING_TRIGGERS,
  PartlistColumn.GEOMETRY_DOCUMENT,
  PartlistColumn.GEOMETRY_SYNC_STATE,
  PartlistColumn.GEOMETRY_KVS_DOCUMENT,
  PartlistColumn.MARKETING_CONDITIONS,
  PartlistColumn.BOM_ZSB,
  PartlistColumn.BOM_INCLUSION_KEY,
  PartlistColumn.BOM_EXCLUSION_KEY,
  PartlistColumn.BOM_LOCATION_VARIANT,
  PartlistColumn.BOM_QUANTITY,
  PartlistColumn.BOM_QUANTITY_UNIT,
  PartlistColumn.GEOMETRY_BRAND,
  PartlistColumn.MASTER_SHEET,
  PartlistColumn.BOM_KIT_FLAG,
  PartlistColumn.CREATED_AT,
  PartlistColumn.LAST_CHANGE_AT,
  PartlistColumn.MARKETING_POSITIONS,
  PartlistColumn.COMMENT,
  PartlistColumn.SOURCE,
  PartlistColumn.ORIGINAL_PART_ID,
  PartlistColumn.BOM_NODE_NAME,
  PartlistColumn.BOM_NODE_DESCRIPTION,
  PartlistColumn.BOM_SEQUENCE,
  PartlistColumn.BOM_BASE_NUMBER,
  PartlistColumn.BOM_PARTLIST_POSITION_NUMBER,
  PartlistColumn.BOM_SUCCESSOR,
  PartlistColumn.BOM_PREDECESSOR,
];
export type PartlistViewOptionsState = {
  columns: string[];
  filterModel: GridFilterModel;
};

const persistedPartlistViewOptions = localStorage.getItem(partlistViewOptionsSliceName);
const persistedState = persistedPartlistViewOptions ? JSON.parse(persistedPartlistViewOptions) as PartlistViewOptionsState : null;
const fallback: PartlistViewOptionsState = {
  columns: partlistColumnIds,
  filterModel: {},
};
const initialState: PartlistViewOptionsState = persistedState ?? fallback;
// if columns does not exist, set default fallback value
if (initialState.columns === undefined || initialState.columns.length === 0) {
  initialState.columns = fallback.columns;
}

const partlistViewOptionsSlice = createSlice({
  name: partlistViewOptionsSliceName,
  initialState,
  reducers: {
    setPartlistViewColumns(state: PartlistViewOptionsState, action: PayloadAction<string[]>) {
      const next: PartlistViewOptionsState = { ...state, columns: action.payload };
      persistState(next);
      return next;
    },
    setPartlistViewFilterModel(state: PartlistViewOptionsState, action: PayloadAction<GridFilterModel>) {
      const next: PartlistViewOptionsState = { ...state, filterModel: action.payload };
      persistState(next);
      return next;
    },
  },
});

export type PartlistViewOptionsRootState = { [partlistViewOptionsSliceName]: PartlistViewOptionsState };
export const selectPartlistViewOptions = (state: PartlistViewOptionsRootState) => state[partlistViewOptionsSliceName];
export const setPartlistViewColumns = (columns: string[]) => (dispatch: Dispatch) => {
  dispatch(partlistViewOptionsSlice.actions.setPartlistViewColumns(columns));
};
export const setPartlistViewFilterModel = (filterModel: GridFilterModel) => (dispatch: Dispatch) => {
  dispatch(partlistViewOptionsSlice.actions.setPartlistViewFilterModel(filterModel));
};

function persistState(state: PartlistViewOptionsState) {
  localStorage.setItem(partlistViewOptionsSliceName, JSON.stringify(state));
}

export const partlistViewOptionsReducer = partlistViewOptionsSlice.reducer;
