import {
  selectGeometryManagerLayoutProfileString,
  selectGeometryViewOptions,
  setGeometryManagerLayoutProfileString,
  setGeometryViewColumns,
} from "@org-avp/avp-avengers-frontend-geometry-services";
import {
  selectMaterialInfoViewOptions,
  selectPartlistViewOptions,
  selectTargetManagerViewOptions,
  selectTmmEditorLayoutProfileString,
  selectTmmViewOptions,
  setMaterialInfoViewColumns,
  setPartlistViewColumns,
  setTargetManagerViewColumns,
  setTmmEditorLayoutProfileString,
  setTmmViewColumns,
  TargetMaterialMode,
} from "@org-avp/avp-avengers-frontend-project-services";
import { selectLanguage, setLanguage } from "@org-avp/avp-avengers-ui-framework-i18n";
import { AvengersTheme, selectTheme, setTheme } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../../app/hooks";
import { LayoutState, selectLayout, setLayout } from "../../../../slices/layoutSlice";
import { LayoutProfile, LayoutProfileDataKey } from "./model";

interface LayoutProfileHelper {
  getCurrentLayoutProfileData: () => Record<string, string | undefined>;
  applyLayoutProfile: (layoutProfile: LayoutProfile) => void;
}

export const useLayoutProfileHelper = (): LayoutProfileHelper => {
  const appDispatch = useAppDispatch();

  const avengersTheme = useSelector(selectTheme);
  const languageState = useSelector(selectLanguage);
  const layoutState = useSelector(selectLayout);
  const projectMaterialInfoViewOptionsState = useSelector(selectMaterialInfoViewOptions);
  const projectPartlistViewOptionsState = useSelector(selectPartlistViewOptions);
  const projectTargetManagerViewOptionsState = useSelector(selectTargetManagerViewOptions);
  const projectTmmViewOptionsState = useSelector(selectTmmViewOptions);
  const projectTmmEditorViewOptionsProfileString = useSelector(selectTmmEditorLayoutProfileString);
  const geometryGeometryManagerOptionsLayoutProfileString = useSelector(selectGeometryManagerLayoutProfileString);
  const geometryViewOptionsState = useSelector(selectGeometryViewOptions);

  // collect current layout data
  const getCurrentLayoutProfileData = useCallback(
    () => {
      const data: Record<string, string | undefined> = {};

      // common
      data[LayoutProfileDataKey.LANGUAGE] = languageState.selectedLanguage;
      data[LayoutProfileDataKey.LAYOUT] = JSON.stringify(layoutState);
      data[LayoutProfileDataKey.THEME] = avengersTheme;

      // project
      data[LayoutProfileDataKey.COLUMN_IDS_PARTLIST_VIEW] = JSON.stringify(projectPartlistViewOptionsState.columns);
      data[LayoutProfileDataKey.COLUMN_IDS_MATERIAL_INFO_VIEW] = JSON.stringify(projectMaterialInfoViewOptionsState.columns);
      data[LayoutProfileDataKey.COLUMN_IDS_TARGET_MANAGER_VIEW] = JSON.stringify(projectTargetManagerViewOptionsState.columns);
      data[LayoutProfileDataKey.COLUMN_IDS_TMM_VIEW] = JSON.stringify(projectTmmViewOptionsState.columns);
      data[LayoutProfileDataKey.LAYOUT_PROFILE_TMM_EDITOR_VIEW] = projectTmmEditorViewOptionsProfileString;

      // geometry
      data[LayoutProfileDataKey.COLUMN_IDS_GEOMETRY_VIEW] = JSON.stringify(geometryViewOptionsState.columns);
      data[LayoutProfileDataKey.LAYOUT_PROFILE_GEOMETRY_MANAGER_VIEW_ASSEMBLY_MANAGER] = geometryGeometryManagerOptionsLayoutProfileString;

      return data;
    }, [
      avengersTheme,
      geometryGeometryManagerOptionsLayoutProfileString,
      geometryViewOptionsState.columns,
      languageState.selectedLanguage,
      layoutState,
      projectMaterialInfoViewOptionsState.columns,
      projectPartlistViewOptionsState.columns,
      projectTargetManagerViewOptionsState.columns,
      projectTmmViewOptionsState.columns,
      projectTmmEditorViewOptionsProfileString,
    ],
  );

  const applyLayoutProfile = useCallback((layoutProfile: LayoutProfile) => {
    console.log(`Apply layout profile: "${layoutProfile.name}".`);

    //
    // project
    //

    // apply partlistView columns
    const partlistViewColumnIds = parseStringArray(layoutProfile.data[LayoutProfileDataKey.COLUMN_IDS_PARTLIST_VIEW]);
    if (partlistViewColumnIds) {
      appDispatch(setPartlistViewColumns(partlistViewColumnIds));
    }

    // apply materialInfoView columns
    const materialInfoViewColumnIds = parseStringArray(layoutProfile.data[LayoutProfileDataKey.COLUMN_IDS_MATERIAL_INFO_VIEW]);
    if (materialInfoViewColumnIds) {
      appDispatch(setMaterialInfoViewColumns(materialInfoViewColumnIds));
    }

    // apply targetManagerView columns
    const targetManagerViewColumnIdsLookup = parseStringArrayRecord(layoutProfile.data[LayoutProfileDataKey.COLUMN_IDS_TARGET_MANAGER_VIEW]);
    if (targetManagerViewColumnIdsLookup) {
      Object.values(TargetMaterialMode).forEach((targetMaterialMode) => {
        const columnIds = targetManagerViewColumnIdsLookup[targetMaterialMode];
        if (columnIds) {
          appDispatch(setTargetManagerViewColumns(targetMaterialMode, columnIds));
        }
      });
    }

    // apply tmmView columns
    const tmmViewColumnIds = parseStringArray(layoutProfile.data[LayoutProfileDataKey.COLUMN_IDS_TMM_VIEW]);
    if (tmmViewColumnIds) {
      appDispatch(setTmmViewColumns(tmmViewColumnIds));
    }

    // apply tmmEditorView layoutProfileString
    const tmmEditorLayoutProfileString = layoutProfile.data[LayoutProfileDataKey.LAYOUT_PROFILE_TMM_EDITOR_VIEW];
    if (tmmEditorLayoutProfileString) {
      appDispatch(setTmmEditorLayoutProfileString(tmmEditorLayoutProfileString));
    }

    //
    // geometry
    //

    // apply geometryMangerView / assemblyManager columns
    const geometryManagerLayoutProfileString = layoutProfile.data[LayoutProfileDataKey.LAYOUT_PROFILE_GEOMETRY_MANAGER_VIEW_ASSEMBLY_MANAGER];
    if (geometryManagerLayoutProfileString) {
      appDispatch(setGeometryManagerLayoutProfileString(geometryManagerLayoutProfileString));
    }

    // apply geometryView columns
    const geometryViewColumnIds = parseStringArray(layoutProfile.data[LayoutProfileDataKey.COLUMN_IDS_GEOMETRY_VIEW]);
    if (geometryViewColumnIds) {
      appDispatch(setGeometryViewColumns(geometryViewColumnIds));
    }

    //
    // common
    //

    // apply theme
    const layoutProfileTheme = layoutProfile.data[LayoutProfileDataKey.THEME];
    if (layoutProfileTheme && Object.values(AvengersTheme).includes(layoutProfileTheme as AvengersTheme)) {
      appDispatch(setTheme(layoutProfileTheme as AvengersTheme));
    }

    // apply language
    const layoutProfileLanguage = layoutProfile.data[LayoutProfileDataKey.LANGUAGE];
    if (layoutProfileLanguage && languageState.availableLanguages.includes(layoutProfileLanguage)) {
      appDispatch(setLanguage(layoutProfileLanguage));
    }

    // apply layout
    const layoutState = parseUncheckedObj<LayoutState>(layoutProfile.data[LayoutProfileDataKey.LAYOUT]);
    if (layoutState) {
      appDispatch(setLayout(layoutState));
    }
  }, [appDispatch, languageState.availableLanguages]);

  return useMemo(() => {
    return {
      applyLayoutProfile,
      getCurrentLayoutProfileData,
    };
  }, [applyLayoutProfile, getCurrentLayoutProfileData]);
};

const parseStringArray = (stringArrayJson?: string): string[] | undefined => {
  if (stringArrayJson) {
    try {
      const stringArray = JSON.parse(stringArrayJson);
      if (isStringArray(stringArray)) {
        return stringArray;
      } else {
        console.warn("Failed to parse string array: Unexpected format.");
      }
    } catch (e) {
      console.warn("Failed to parse string array", e);
    }
  }
  return undefined;
};

const parseStringArrayRecord = (recordJson?: string): Record<string, string[] | undefined> | undefined => {
  if (recordJson) {
    try {
      const maybeRecord = JSON.parse(recordJson);
      if (typeof maybeRecord === "object" && Object.values(maybeRecord).every((value) => isStringArray(value))) {
        return maybeRecord;
      } else {
        console.warn("Failed to parse record: Unexpected format.");
      }
    } catch (e) {
      console.warn("Failed to parse record", e);
    }
  }
  return undefined;
};

const isStringArray = (maybeStringArray: unknown): maybeStringArray is string[] => {
  return Array.isArray(maybeStringArray) && maybeStringArray.every((it) => typeof it === "string");
};

const parseUncheckedObj: <T>(anyJson?: string) => T | undefined = (anyJson) => {
  if (anyJson) {
    try {
      const maybeObject = JSON.parse(anyJson);
      if (typeof maybeObject === "object") {
        return maybeObject;
      }
    } catch (e) {
      console.warn("Failed to parse any", e);
    }
  }
  return undefined;
};
