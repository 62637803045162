import { prepareHeaders } from "@org-avp/avp-avengers-ui-framework-services";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../environment";

export type DownloadUrlRequest = { asConnectorVersion: string; webIdentityToken: string };
export type DownloadUrlResponse = { downloadUrl: string };

export const asConnectorApiKey = "asConnectorApi" as const;
export const asConnectorApi = createApi({
  reducerPath: asConnectorApiKey,
  baseQuery: fetchBaseQuery({
    baseUrl: environment.NX_AS_CONNECTOR_DOWNLOAD_URL,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    // disable eslint rule: the second type argument indicates the types of the arguments for the "query" function, which has no args in this case
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getRequiredVersion: builder.query<string, void>({
      query: () => ({
        method: "GET", url: "",
        responseHandler: (response) => {
          return Promise.resolve(response.text());
        },
      }),
    }),
    getDownloadUrl: builder.mutation<DownloadUrlResponse, DownloadUrlRequest>({
      query: (requestBody) => ({
        method: "POST", url: "", body: requestBody,
        responseHandler: (response) => {
          return Promise.resolve(response.json());
        },
      }),
    }),
  }),
});

export const {
  useGetRequiredVersionQuery,
  useGetDownloadUrlMutation,
} = asConnectorApi;
