import { HomeOutlined, InfoCircleOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";
import { Translations } from "@org-avp/avp-avengers-ui-framework-i18n";
import { ModuleDef } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { AuthGuard, CrudOp, projectManagementAuthScope, roleAssignmentOverviewAuthScope } from "@org-avp/avp-avengers-ui-framework-oidc";
import { projectApi } from "@org-avp/avp-avengers-ui-framework-services/project";
import { userApi } from "@org-avp/avp-avengers-ui-framework-services/user";
import { FC } from "react";
import { useParams } from "react-router-dom";
import de from "../../../public/locales/de.json";
import en from "../../../public/locales/en.json";
import { HomeView } from "../../components/home-view/HomeView";
import { ImprintView } from "./components/imprint-view/ImprintView";
import { OperatorView } from "./components/operator-view/OperatorView";
import { RoleManagementView } from "./components/role-management-view/RoleManagementView";
import { SettingsView } from "./components/settings-view/SettingsView";

const translations: Translations = { de, en };

const storeMiddlewares = [
  projectApi.middleware,
  userApi.middleware,
];

const reducers = {
  [projectApi.reducerPath]: projectApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
};

const GuardedOperatorView: FC = () => {
  const { projectId, subprojectId } = useParams();
  if (projectId == null || subprojectId == null) {
    throw new Error("not in a subproject");
  }

  return (
    <AuthGuard authScope={projectManagementAuthScope} crudOp={CrudOp.READ} project={projectId}>
      <OperatorView projectId={projectId} subprojectId={subprojectId}/>
    </AuthGuard>
  );
};

const GuardedRoleManagementView: FC = () => (
  <AuthGuard authScope={roleAssignmentOverviewAuthScope} crudOp={CrudOp.READ}>
    <RoleManagementView/>
  </AuthGuard>
);

export const appModule: ModuleDef = {
  moduleKey: "app",
  fullSizeViews: [
    {
      viewKey: "home",
      viewType: "overlay",
      displayName: "app:homeView.title",
      el: <HomeView/>,
      menuInclusion: "top",
      menuIcon: <HomeOutlined/>,
      hasMenuDividerBelow: true,
    },
    {
      viewKey: "project/:projectId/:subprojectId",
      displayName: "Project",
      el: <GuardedOperatorView/>,
      isAuthorized: (authHelperCreator, { project }) => {
        const { evaluate } = authHelperCreator(projectManagementAuthScope);
        return project != null && evaluate(CrudOp.READ, project);
      },
    },
    {
      viewKey: "roleManagement",
      viewType: "overlay",
      displayName: "app:roleManagementView.title",
      el: <GuardedRoleManagementView/>,
      menuInclusion: "bottom",
      menuIcon: <UsergroupAddOutlined/>,
      isAuthorized: (authHelperCreator) => {
        const { evaluate } = authHelperCreator(roleAssignmentOverviewAuthScope);
        return evaluate(CrudOp.READ);
      },
    },
    {
      viewKey: "imprint",
      viewType: "overlay",
      displayName: "app:imprintView.imprint.label",
      el: <ImprintView/>,
      menuInclusion: "bottom",
      menuIcon: <InfoCircleOutlined/>,
    },
    {
      viewKey: "settings",
      viewType: "overlay",
      displayName: "app:settingsView.title",
      el: <SettingsView/>,
      menuInclusion: "bottom",
      menuIcon: <UserOutlined/>,
    },
  ],
  reducers,
  storeMiddlewares,
  translations: [
    translations,
  ],
};
