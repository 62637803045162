import { CloseCircleFilled } from "@ant-design/icons";
import {
  discardFirstErrorResponse,
  ErrorResponse,
  ErrorResponseType,
  selectFirstErrorResponse,
  TimeoutErrorResponse,
} from "@org-avp/avp-avengers-ui-framework-error-response";
import { ButtonProps, Modal } from "antd";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AdviseErrorResponseModal } from "./AdviseErrorResponseModal";
import styles from "./ErrorResponseDialog.module.less";
import { TitleMessage } from "./TitleMessage";

export interface ErrorResponseModalProps<T extends ErrorResponse> {
  errorResponse: T;
  onOk: () => void;
}

export const ErrorResponseDialog = () => {
  const appDispatch = useAppDispatch();
  const errorResponse = useAppSelector(selectFirstErrorResponse);
  useEffect(
    () => {
      // discard unknown error responses for now (only show advise and timeout errors)
      if (errorResponse?.type === ErrorResponseType.UNKNOWN) {
        appDispatch(discardFirstErrorResponse());
      }
    },
    [appDispatch, errorResponse],
  );

  const onOk = useCallback(
    () => appDispatch(discardFirstErrorResponse()),
    [appDispatch],
  );

  return (
    <>
      {errorResponse?.type === ErrorResponseType.ADVISE && <AdviseErrorResponseModal errorResponse={errorResponse} onOk={onOk}/>}
      {errorResponse?.type === ErrorResponseType.TIMEOUT && <TimeoutErrorResponseModal errorResponse={errorResponse} onOk={onOk}/>}
    </>
  );
};

const cancelButtonProps: ButtonProps = { style: { display: "none" } };
type TimeoutErrorResponseModalProps = ErrorResponseModalProps<TimeoutErrorResponse>;
export const TimeoutErrorResponseModal: FC<TimeoutErrorResponseModalProps> = ({ onOk }) => {
  const { t } = useTranslation("app", { keyPrefix: "errorResponse.timeout" });
  return (
    <Modal open={true} centered={true} closeIcon={false} onOk={onOk} cancelButtonProps={cancelButtonProps}>
      <div className={styles.content}>
        <div className={styles.icon}><CloseCircleFilled/></div>
        <div className={styles.errorContainer}>
          <TitleMessage title={t("title")} message={t("message")}/>
        </div>
      </div>
    </Modal>
  );
};
