import { FilterChangedEvent, ITextFloatingFilterParams, NumberFilterModel, TextFloatingFilter } from "ag-grid-community";

/**
 * Custom floating filter extends from TextFloatingFilter but use for number. NumberFloatingFilter is not available in these context.
 */
export class ExNumberFloatingFilterComponent extends TextFloatingFilter {
  init(params: ITextFloatingFilterParams) {
    super.init(params);

    const filterInputField = this.getGui().querySelector("input");

    let timeoutId: ReturnType<typeof setTimeout>;
    const onInputBoxChanged = () => {
      if (filterInputField) {
        // stop existing timeout
        if (timeoutId)
          clearTimeout(timeoutId);

        const filter = filterInputField.value.length === 0 || isNaN(+filterInputField.value) ? undefined : +filterInputField.value;

        timeoutId = setTimeout(() => {
          const columnFilterModel = params.api.setColumnFilterModel<NumberFilterModel>(params.column, { type: "equals", filterType: "number", filter: filter });
          columnFilterModel.finally(() => {
            params.api.onFilterChanged();
          });
        }, 500);
      }
    };

    // when input field found, add eventListener for changes
    if (filterInputField)
      filterInputField.addEventListener("input", onInputBoxChanged);
  }

  // The type of parentModel is set to any because the original filterModel is of type TextFilterModel, but above as NumberFilterModel.
  // The type is on these place not relevant.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onParentModelChanged(parentModel: any, event: FilterChangedEvent) {
    super.onParentModelChanged(parentModel, event);
    this.setEditable(true);
  }
}
