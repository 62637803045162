import { IconProps } from "@org-avp/avp-avengers-ui-framework-util";
import { FC } from "react";
import { LayoutProfileIcon } from "../../../common/icons/LayoutProfileIcon";

import styles from "./CharLayoutProfileIcon.module.less";

export interface CharLayoutProfileIconProps extends IconProps {
  char?: string;
}

export const CharLayoutProfileIcon: FC<CharLayoutProfileIconProps> = ({ char, onClick, ...iconProps }) => {
  return (
    <span className={styles.stack} onClick={onClick}>
      <LayoutProfileIcon {...iconProps}/>
      <span className={styles.char}>{char?.at(0)}</span>
    </span>
  );
};
