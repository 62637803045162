import { Brand } from "@org-avp/avp-avengers-project-service-api";
import { userApi } from "@org-avp/avp-avengers-ui-framework-services/user";
import { User } from "@org-avp/avp-avengers-user-service-api";
import { useCallback, useMemo } from "react";

interface KvsMainClientHelper {
  isLoading: boolean;
  kvsMainClientUserId?: string;
  onUserSelect: (user: User) => void;
}

export const useKvsMainClientHelper = (brand: Brand): KvsMainClientHelper => {
  const { data: brandSettings } = userApi.useGetBrandSettingsQuery(brand);

  const [setKvsMainClientMutation] = userApi.useSetKvsMainClientMutation();

  const onUserSelect = useCallback((user: User) => {
    setKvsMainClientMutation([brand, user.userId]);
  }, [brand, setKvsMainClientMutation]);

  return useMemo(() => {
    return ({
      isLoading: brandSettings === undefined,
      kvsMainClientUserId: brandSettings?.kvsMainClient?.userId,
      onUserSelect,
    });
  }, [brandSettings, onUserSelect]);
};
