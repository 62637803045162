import { Button, Tooltip } from "antd";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CharLayoutProfileIcon } from "./CharLayoutProfileIcon";
import { LayoutProfileButton } from "./LayoutProfileButton";
import { LayoutProfileDialog } from "./LayoutProfileDialog";

import styles from "./LayoutProfiles.module.less";
import { useLayoutProfilesHelper } from "./use-layout-profiles-helper";

export const LayoutProfiles: FC = () => {
  const { t } = useTranslation();

  const layoutProfilesHelper = useLayoutProfilesHelper();

  const createLayoutProfile = useCallback(() => {
    layoutProfilesHelper.openLayoutProfileDialog();
  }, [layoutProfilesHelper]);

  return (
    <>
      {layoutProfilesHelper.layoutProfileDialogProps && <LayoutProfileDialog {...layoutProfilesHelper.layoutProfileDialogProps}/>}
      <div className={styles.container}>
        {
          layoutProfilesHelper.layoutProfiles.map(
            (layoutProfile, index) => (
              <LayoutProfileButton
                key={index}
                layoutProfileIndex={index}
                name={layoutProfile.name}
                applyLayoutProfile={layoutProfilesHelper.applyLayoutProfile}
                saveLayoutProfile={layoutProfilesHelper.updateLayoutProfileData}
                editLayoutProfile={layoutProfilesHelper.openLayoutProfileDialog}
                deleteLayoutProfile={layoutProfilesHelper.deleteLayoutProfile}
              />
            ),
          )
        }
        <Tooltip title={t("app:operatorView.layoutProfiles.create")} placement="bottomLeft" key={layoutProfilesHelper.layoutProfiles.length}>
          <Button
            type="text"
            icon={<CharLayoutProfileIcon char="+"/>}
            disabled={layoutProfilesHelper.isCreateLayoutProfileDisabled}
            onClick={createLayoutProfile}
          />
        </Tooltip>
      </div>
    </>
  );
};
