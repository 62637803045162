import { Brand, Project } from "@org-avp/avp-avengers-project-service-api";
import { projectApi } from "@org-avp/avp-avengers-ui-framework-services/project";
import { userApi } from "@org-avp/avp-avengers-ui-framework-services/user";
import { stringifyProjectName } from "@org-avp/avp-avengers-ui-framework-util";
import { ProjectUserRoles, User } from "@org-avp/avp-avengers-user-service-api";
import { useCallback, useMemo, useState } from "react";
import { AddUserData } from "./dialogs/add-user-dialog/AddUserDialog";

export interface RoleAssignmentHelper {
  projects: Project[];
  kiraIds: string[];
  projectUserRoles: ProjectUserRoleRow[];

  addUserDialogOpen: boolean;
  onAddUserButtonClick: () => void;
  onAddUserDialogClose: (userData?: AddUserData) => void;

  onSave: (editedRows: Set<ProjectUserRoleRow>) => void;
}

export interface ProjectUserRoleRow {
  project: Project;
  user: User;
  roles: string[];
}

export function useRoleAssignmentHelper(brand: Brand, onSaveSuccess: () => void): RoleAssignmentHelper {
  const { data: projects } = projectApi.useGetProjectsQuery(brand);
  const sortedProjects = useMemo(() =>
    (projects ? [...projects] : []).sort((a, b) => stringifyProjectName(a.name).localeCompare(stringifyProjectName(b.name))), [projects]);

  const { data: users } = userApi.useGetAllUsersQuery();
  const { data: roles } = userApi.useGetProjectUserRolesQuery(brand);

  const [updateProjectRolesMutation] = userApi.useUpdateProjectRolesMutation();

  const [createUserMutationTrigger] = userApi.useCreateUserMutation();

  const kiraIds = useMemo(() => users?.users.map((user) => user.kiraId) ?? [], [users?.users]);

  const projectUserRoles = useMemo((): ProjectUserRoleRow[] => {
    const roleRows: ProjectUserRoleRow[] = [];
    sortedProjects?.forEach((project) => {
      users?.users.forEach((user) => {
        const roleArray = roles?.projectUserRoles.find((r) => r.projectId === project.projectId && r.userId === user.userId)?.roles ?? [];
        roleRows.push({ project, user, roles: roleArray });
      });
    });
    return roleRows;
  }, [sortedProjects, users?.users, roles?.projectUserRoles]);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const onAddUserButtonClick = useCallback(() => setAddUserDialogOpen(true), []);

  const onAddUserDialogClose = useCallback((userData?: AddUserData) => {
    if (userData) {
      const lastName = userData.lastNameInputField === "" ? undefined : userData.lastNameInputField;
      const firstName = userData.firstNameInputField === "" ? undefined : userData.firstNameInputField;
      const user: User = {
        userId: "",
        kiraId: userData.kiraIdInputField,
        lastName,
        firstName,
      };
      createUserMutationTrigger(user).unwrap().then(() => {
        setAddUserDialogOpen(false);
      }).catch((err) => console.error(err));
    } else {
      setAddUserDialogOpen(false);
    }
  }, [createUserMutationTrigger]);

  const onSave = useCallback((editedRows: Set<ProjectUserRoleRow>) => {
    const projectUserRoles: ProjectUserRoles[] = [...editedRows].map(({ project, user, roles }) => ({
      projectId: project.projectId,
      userId: user.userId,
      roles,
    }));
    updateProjectRolesMutation({ projectUserRoles, brand }).unwrap().then(() => {
      onSaveSuccess();
    }).catch((err) => console.error(err));
  }, [updateProjectRolesMutation, brand, onSaveSuccess]);

  return useMemo((): RoleAssignmentHelper => ({
    projects: sortedProjects ?? [],
    kiraIds,
    projectUserRoles,
    addUserDialogOpen,
    onAddUserButtonClick,
    onAddUserDialogClose,
    onSave,
  }), [sortedProjects, kiraIds, projectUserRoles, addUserDialogOpen, onAddUserButtonClick, onAddUserDialogClose, onSave]);
}
