import { CloseCircleFilled } from "@ant-design/icons";
import { AdviseErrorResponse, AdviseType } from "@org-avp/avp-avengers-ui-framework-error-response";
import { ButtonProps, Modal, Tooltip } from "antd";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ErrorResponseModalProps } from "./ErrorResponseDialog";
import styles from "./ErrorResponseDialog.module.less";
import { TitleMessage } from "./TitleMessage";

const cancelButtonProps: ButtonProps = { style: { display: "none" } };
type AdviseErrorResponseModalProps = ErrorResponseModalProps<AdviseErrorResponse>;
export const AdviseErrorResponseModal: FC<AdviseErrorResponseModalProps> = ({ errorResponse, onOk }) => {
  return (
    <Modal open={true} centered={true} closeIcon={false} onOk={onOk} cancelButtonProps={cancelButtonProps}>
      <div className={styles.content}>
        <div className={styles.icon}><CloseCircleFilled/></div>
        <AdviseErrorContainer errorResponse={errorResponse}/>
      </div>
    </Modal>
  );
};

const AdviseErrorContainer: FC<{ errorResponse: AdviseErrorResponse }> = ({ errorResponse }) => {
  const advise = errorResponse.data.advise;
  if (advise.validationViolations) {
    const param: ValidationErrorParam = {
      title: advise.title,
      message: advise.message,
      validationViolations: advise.validationViolations,
    };
    return <ValidationError {...param}/>;
  } else {
    const param: TechnicalErrorParam = {
      title: advise.title,
      message: advise.message,
      technicalInfo: advise.uuid
        ? {
            serviceId: errorResponse.data.type,
            errorId: advise.uuid,
            timestamp: advise.timestamp,
          }
        : undefined,
    };
    return <TechnicalError {...param}/>;
  }
};

// ================================================================================

interface ValidationErrorParam {
  title?: string | null;
  message?: string | null;
  validationViolations: Record<string, string[]>;
}

const ValidationError: FC<ValidationErrorParam> = (param) => {
  const { t } = useTranslation("app", { keyPrefix: "errorResponse" });
  const title: string = param.title ?? t([`fallback.${AdviseType.VALIDATION}.title`, "fallback.title"], { defaultValue: "Validation Error" });
  const message: string = param.message ?? t([`fallback.${AdviseType.VALIDATION}.message`, "fallback.message"], { defaultValue: "Validation failed on server!" });

  const validationItems = useMemo(
    () => Object.keys(param.validationViolations).sort().map((it, index) =>
      <li key={index}><ValidationItem name={it} violations={param.validationViolations[it]}/></li>,
    ),
    [param.validationViolations],
  );

  return (
    <div className={styles.errorContainer}>
      <TitleMessage title={title} message={message}/>
      <div className={styles.detail}>
        <div>{t("VALIDATION.items", { defaultValue: "Validation failed for following items:" })}</div>
        <div>
          <ul>{validationItems}</ul>
        </div>
      </div>
    </div>
  );
};

const ValidationItem: FC<{ name: string; violations: string[] }> = ({ name, violations }) => {
  return (
    <Tooltip title={<ItemTooltip constraints={violations}/>}>
      {`${name} (${violations.length})`}
    </Tooltip>
  );
};

const ItemTooltip: FC<{ constraints: string[] }> = ({ constraints }) => {
  return (<ul>{constraints.map((it, index) => <li key={index}>{it}</li>)}</ul>);
};

// ================================================================================

interface TechnicalErrorParam {
  title?: string | null;
  message?: string | null;
  technicalInfo?: TechnicalInfoParam | null;
}

const TechnicalError: FC<TechnicalErrorParam> = (param) => {
  const { t } = useTranslation("app", { keyPrefix: "errorResponse" });
  const title: string = param.title ?? t([`fallback.${AdviseType.ERROR}.title`, "fallback.title"], { defaultValue: "Technical Error" });
  const message: string = param.message ?? t([`fallback.${AdviseType.ERROR}.message`, "fallback.message"], { defaultValue: "An error occurred!" });
  return (
    <div className={styles.errorContainer}>
      <TitleMessage title={title} message={message}/>
      {param.technicalInfo && <TechnicalInfo {...param.technicalInfo}/>}
    </div>
  );
};

// ================================================================================

interface TechnicalInfoParam {
  serviceId: string;
  errorId: string;
  timestamp: string;
}

const TechnicalInfo: FC<TechnicalInfoParam> = ({ serviceId, errorId, timestamp }) => {
  const { t } = useTranslation("app", { keyPrefix: "errorResponse.technicalInfo" });
  return (
    <div className={styles.detail}>
      <div>
        {`${t("serviceId", { defaultValue: "ServiceId" })}: ${serviceId}`}
      </div>
      <div>
        {`${t("errorId", { defaultValue: "ErrorId" })}: ${errorId}`}
      </div>
      <div>
        {`${t("timestamp", { defaultValue: "Timestamp" })}: ${timestamp}`}
      </div>
    </div>
  );
};
