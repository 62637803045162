import { MarketingRuleData } from "@org-avp/avp-avengers-partlist-service-api";

export enum EditPartlistMarketingDataDialogMode {
  SINGLE,
  MULTI,
}

export interface EditPartlistMarketingDataDialogDataEntry {
  partlistEntryId: string;
  materialNumber: string;
  originalRule?: string;
  marketingRuleData: MarketingRuleData[];
}

export interface EditPartlistMarketingDataDialogData {
  dialogMode: EditPartlistMarketingDataDialogMode;
  dialogDataEntries: EditPartlistMarketingDataDialogDataEntry[];
  isShowGeometryDisabled: boolean;
}
