import { DownloadOutlined, FolderOpenOutlined, SyncOutlined } from "@ant-design/icons";
import { useCognitoIdToken } from "@org-avp/avp-avengers-ui-framework-cognito-identity";
import { AsConnectorAcquireParam, ElectronAPI, getElectronAPI } from "@org-avp/avp-avengers-ui-framework-electron-api";
import { Button, Flex, Spin, Tooltip } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { asConnectorApi } from "../../../services/as-connector-service";

import styles from "./AsConnectorManager.module.less";

type AsConnectorVersionState =
  | "PENDING" // DLL version is not yet determined.
  | "OUTDATED" // DLL version is outdated.
  | "UP_TO_DATE" // DLL is up to date.
  ;

export const AsConnectorManager: FC = () => {
  useTranslation();
  const electronAPI = getElectronAPI();
  return (
    <>
      {electronAPI !== undefined && <AsConnectorArtifactManager electronAPI={electronAPI}/>}
    </>
  );
};

const AsConnectorArtifactManager: FC<{ electronAPI: ElectronAPI }> = ({ electronAPI }) => {
  const { t } = useTranslation("app", { keyPrefix: "homeView.asConnectorManager" });

  const cognitoIdToken = useCognitoIdToken();

  // get current AsConnector DLL version
  const [currentAsConnectorVersion, setCurrentAsConnectorVersion] = useState<string>();
  electronAPI.invoke
    .getAsConnectorDllVersion()
    .then(setCurrentAsConnectorVersion);

  // check if artifact for current version is available
  const [isCurrentAsConnectorArtifactAvailable, setIsCurrentAsConnectorArtifactAvailable] = useState(false);
  useEffect(() => {
    if (currentAsConnectorVersion !== undefined) {
      electronAPI.invoke.ascArtifactIsAvailable(currentAsConnectorVersion)
        .then(setIsCurrentAsConnectorArtifactAvailable);
    }
  }, [currentAsConnectorVersion, electronAPI.invoke]);

  // fetch required asConnector DLL version for current stage
  const { data: requiredAsConnectorVersion } = asConnectorApi.useGetRequiredVersionQuery();

  // check for post install hint: "update authoring system plugins"
  const [hasPostInstallHint, setHasPostInstallHint] = useState<boolean>();
  electronAPI.invoke
    .ascArtifactGetPostInstallHint()
    .then((hint) => setHasPostInstallHint(hint !== undefined));

  // evaluate asConnector version state
  const [ascVersionState, setAscVersionState] = useState<AsConnectorVersionState>("PENDING");
  useEffect(() => {
    setAscVersionState(getAscVersionState(currentAsConnectorVersion, requiredAsConnectorVersion));
  }, [currentAsConnectorVersion, requiredAsConnectorVersion]);

  // a progress flag
  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const [getDownloadUrl] = asConnectorApi.useGetDownloadUrlMutation();
  const acquireAsConnectorArtifact = useCallback((installAsConnectorFiles: boolean) => {
    if (!isInProgress && cognitoIdToken && requiredAsConnectorVersion) {
      console.info(`AscArtifact: version="${requiredAsConnectorVersion}", install=${installAsConnectorFiles}.`);
      setIsInProgress(true);
      setTimeout(() => { // show "inProgress" before continue
        console.info(`AscArtifact "${requiredAsConnectorVersion}": Request download URL.`);
        getDownloadUrl({
          asConnectorVersion: requiredAsConnectorVersion,
          webIdentityToken: cognitoIdToken,
        }).unwrap()
          .then((downloadUrlResponse) => {
            const acquireParam: AsConnectorAcquireParam = {
              asConnectorVersion: requiredAsConnectorVersion,
              downloadURL: downloadUrlResponse.downloadUrl,
              forceDownload: false,
              forceUnzip: false,
              installPendingUpdate: installAsConnectorFiles,
            };
            console.info(`AscArtifact "${requiredAsConnectorVersion}": Acquire.`, acquireParam);
            return electronAPI.invoke.ascArtifactAcquire(acquireParam).then(() => {
              if (installAsConnectorFiles) {
                console.info(`AscArtifact "${requiredAsConnectorVersion}": Trigger application re-launch to apply pending updates!`);
                return electronAPI.invoke.ascArtifactRelaunchApplication();
              } else {
                console.info(`AscArtifact "${requiredAsConnectorVersion}": Download finished.`);
                return Promise.resolve();
              }
            });
          })
          .catch((e) => {
            console.warn(`AscArtifact "${requiredAsConnectorVersion}": Failure!`, e);
          })
          .finally(() => {
            console.info(`AscArtifact "${requiredAsConnectorVersion}": Finished!`);
            setIsInProgress(false);
            setIsCurrentAsConnectorArtifactAvailable(true);
          });
      }, 250);
    }
  }, [cognitoIdToken, electronAPI.invoke, getDownloadUrl, isInProgress, requiredAsConnectorVersion]);

  const openAsConnectorFolder = useCallback(() => {
    if (requiredAsConnectorVersion) {
      console.info(`AscArtifact "${requiredAsConnectorVersion}": Open folder.`);
      setIsInProgress(true);
      setTimeout(() => { // show "inProgress" before continue
        const clearPostInstallHint = true;
        electronAPI.invoke
          .ascArtifactOpenFolder(requiredAsConnectorVersion, true, clearPostInstallHint) // unzip before open: ensure all files are available
          .then(() => {
            if (clearPostInstallHint) setHasPostInstallHint(undefined);
            setIsInProgress(false);
          });
      }, 250);
    }
  }, [electronAPI.invoke, requiredAsConnectorVersion]);

  const onUpdateAsConnectorVersion = useCallback(() => {
    acquireAsConnectorArtifact(true);
  }, [acquireAsConnectorArtifact]);

  const onDownloadAsConnectorArtifact = useCallback(() => {
    acquireAsConnectorArtifact(false);
  }, [acquireAsConnectorArtifact]);

  return (
    <>
      <div className={styles.headline}><Trans ns="app" i18nKey="homeView.asConnectorManager.title"/></div>
      <Spin spinning={currentAsConnectorVersion === undefined || requiredAsConnectorVersion === undefined}>
        <Flex className={styles.container} justify="flex-start" vertical={true}>
          <Flex justify="flex-start" align="center" gap="middle">
            {/* info message */}
            <Flex justify="flex-start" align="flex-start" vertical={true}>
              <div>{t("versionInfo", { version: currentAsConnectorVersion })}</div>
              {ascVersionState === "OUTDATED" && (
                <div className={styles.outdatedHint}>{t("versionState.OUTDATED.message")}</div>
              )}
              {ascVersionState === "UP_TO_DATE" && isCurrentAsConnectorArtifactAvailable && (
                <div className={styles.upToDateHint}>{t("versionState.UP_TO_DATE.artifactAvailable.message")}</div>
              )}
              {ascVersionState === "UP_TO_DATE" && !isCurrentAsConnectorArtifactAvailable && (
                <div className={styles.upToDateHint}>{t("versionState.UP_TO_DATE.artifactMissing.message")}</div>
              )}
            </Flex>
            {/* button */}
            <div>
              {ascVersionState === "OUTDATED" && (
                <Tooltip title={`${currentAsConnectorVersion} -> ${requiredAsConnectorVersion}`}>
                  <Button
                    type="primary"
                    icon={isInProgress ? <SyncOutlined spin={isInProgress}/> : undefined}
                    disabled={isInProgress}
                    onClick={onUpdateAsConnectorVersion}
                  >
                    <Trans ns="app" i18nKey="homeView.asConnectorManager.button.OUTDATED"/>
                  </Button>
                </Tooltip>
              )}
              {ascVersionState === "UP_TO_DATE" && !isCurrentAsConnectorArtifactAvailable && (
                <Button
                  type="primary"
                  icon={isInProgress ? <SyncOutlined spin={isInProgress}/> : <DownloadOutlined/>}
                  disabled={isInProgress}
                  onClick={onDownloadAsConnectorArtifact}
                >
                  <Trans ns="app" i18nKey="homeView.asConnectorManager.button.UP_TO_DATE.downloadArtifact"/>
                </Button>
              )}
              {ascVersionState === "UP_TO_DATE" && isCurrentAsConnectorArtifactAvailable && (
                <Tooltip title={requiredAsConnectorVersion}>
                  <Button
                    type="primary"
                    disabled={isInProgress}
                    icon={isInProgress ? <SyncOutlined spin={isInProgress}/> : <FolderOpenOutlined/>}
                    onClick={openAsConnectorFolder}
                  >
                    <Trans ns="app" i18nKey="homeView.asConnectorManager.button.UP_TO_DATE.openFolder"/>
                  </Button>
                </Tooltip>
              )}
            </div>
          </Flex>
          {/* additional hint */}
          {ascVersionState === "OUTDATED" && (
            <div className={styles.message}>{t("versionState.OUTDATED.hint", { version: requiredAsConnectorVersion })}</div>
          )}
          {ascVersionState === "UP_TO_DATE" && isCurrentAsConnectorArtifactAvailable && hasPostInstallHint && (
            <div className={styles.message}>{t("versionState.UP_TO_DATE.artifactAvailable.hint")}</div>
          )}
          {ascVersionState === "UP_TO_DATE" && !isCurrentAsConnectorArtifactAvailable && (
            <div className={styles.message}>{t("versionState.UP_TO_DATE.artifactMissing.hint")}</div>
          )}
        </Flex>
      </Spin>
    </>
  );
};

const getAscVersionState = (currentAsConnectorVersion: string | undefined, requiredAsConnectorVersion: string | undefined): AsConnectorVersionState => {
  if (currentAsConnectorVersion === undefined || requiredAsConnectorVersion === undefined)
    return "PENDING";

  if (currentAsConnectorVersion === requiredAsConnectorVersion)
    return "UP_TO_DATE";

  return "OUTDATED";
};
