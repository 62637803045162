import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleColDef } from "./use-roleAssignment-columnDefs";

export const useColDefFirstName = (): RoleColDef => {
  const { t } = useTranslation();

  return useMemo(() => ({
    colId: "firstName",
    field: "user.firstName",
    headerName: t("app:roleManagementView.field.firstName.label") as string,
  }), [t]);
};
