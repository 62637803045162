import { I18n } from "@org-avp/avp-avengers-ui-framework-i18n";
import { selectTranslations } from "@org-avp/avp-avengers-ui-framework-microfrontend";
import { StrictMode } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { createRouter } from "./app/router";
import { createStore } from "./app/store";
import { AvengersThemeProvider } from "./components/theme-provider/AvengersThemeProvider";
import "./styles/index.less";

// renders the React UI into the provided root element
export const renderAppShell = (rootEl: HTMLElement) => {
  const store = createStore();
  const translations = selectTranslations();
  const router = createRouter();
  createRoot(rootEl).render(
    <StrictMode>
      <Provider store={store}>
        <AvengersThemeProvider>
          <I18n specifiedTranslations={translations}/>
          <DndProvider backend={HTML5Backend}>
            <RouterProvider router={router}/>
          </DndProvider>
        </AvengersThemeProvider>
      </Provider>
    </StrictMode>,
  );
};
