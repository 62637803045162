import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LayoutBase } from "rc-dock";
import { AppDispatch } from "../../../app/store";

export const layoutSliceName = "layout";
export type LayoutState = Omit<LayoutBase, "windowbox">;

const persistedLayout = localStorage.getItem(layoutSliceName);
const persistedState = persistedLayout ? JSON.parse(persistedLayout) : null;
const initialState: LayoutState = persistedState ?? {
  dockbox: {
    mode: "horizontal",
    children: [],
  },
};

const layoutSlice = createSlice({
  name: layoutSliceName,
  initialState,
  reducers: {
    setLayout(state: LayoutState, action: PayloadAction<LayoutState>) {
      return action.payload;
    },
  },
});

export type LayoutRootState = { [layoutSliceName]: LayoutState };
export const selectLayout = (state: LayoutRootState) => state[layoutSliceName];
export const setLayout = (layout: LayoutState) => (dispatch: AppDispatch) => {
  localStorage.setItem(layoutSliceName, JSON.stringify(layout));
  dispatch(layoutSlice.actions.setLayout(layout));
};

export default layoutSlice.reducer;
