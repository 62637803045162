import { Button } from "antd";
import { Trans } from "react-i18next";
import { layoutSliceName } from "../../../slices/layoutSlice";

export const LayoutReset = () => {
  const removeLayoutFromLocalStorage = () => {
    localStorage.removeItem(layoutSliceName);
    window.location.reload();
  };

  return (
    <Button onClick={removeLayoutFromLocalStorage}><Trans ns="app" i18nKey="settingsView.buttonReset"/></Button>
  );
};
